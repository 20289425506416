import { Brand, Order, OrderManager, Period, Store, OrderItem, Discount, DiscountManager, Menu, Benefit, Condition, MathUtils,  } from 'aigens-ng-core';
import {Injectable} from '@angular/core';
import { OrderService } from '../services/order.service';
import { AuthService } from '../auth/auth.service';


@Injectable()
export class MultOrderManager {
    orderManagerMap: {};
    currentPeriods: {};
    enableMutlOrder = false;
    brand: Brand;
    courtDiscount: Discount[];
    stores: Store[];
    sumDiscount = 0;
    orderDisountMap: any = {};
    storesMap = {};
    promoAmount = 0;
    amountDiscount: any = {};
    typeAllDiscount: Discount; // type =All 或者 系'firstTime' discount
    constructor(public discountManager: DiscountManager, public orderService: OrderService, public authService: AuthService) {
        this.orderManagerMap = {};
        this.currentPeriods = {};
    }

    setOrderManagerMap(orderManager: OrderManager, store: Store ) {
        this.orderManagerMap[store.id] = orderManager;
        this.storesMap[store.id] = store;
    }
    calOrderWithDiscount(discountCode?, isEdit = false, callback?) {


            let orders = this.getOrders() || [];
            this.orderDisountMap = {};
            if (orders.length > 0 && this.courtDiscount) {
                this.amountDiscount = {};
                for (let i = 0; i < orders.length; i++) {
                    let storeId = orders[i].storeId;
                    let discounts = this.getAvailableDiscount(this.courtDiscount, orders[i].brandId, orders[i]) || [];
                    let tableLog: any = [['name', 'brandId', 'conditions', 'group/priority']];
                    discounts.map((d) => {
                        tableLog.push([`${d.name}`, `${d.brandIds || 'null'}`, `${JSON.stringify(d.conditions)}`, `${d.group + '/' + d.priority}`]);
                    });
                    if (discountCode) {
                        this.orderService.discountCode = discountCode;
                    }
                    orders[i].promotions = discounts;
                    // discounts = discounts.filter((d) => d.id === '232fb6a9-e30a-40af-b59f-f5924e4cccea');
                    // discounts[0].benefits[0].max = 3;
                    if (discounts.length > 0) {
                        this.discountManager.applyOrderPromotions(orders[i], discounts, this.getOrderManager(storeId), this.authService.checkIsStaff(), this);
                        callback && callback(this.getOrderManager(storeId));
                    }
                }
                this.getSumDiscount();
            }

    }
    getStoreById(id) {
        if (this.stores && this.stores.length > 0 ) {
           return this.stores.filter((s) => s.id === id)[0] || undefined;
        }
    }
    isApplyDiscountCodeSuccess(): boolean {
        let success = false;
        let orders = this.getOrders();
        if (orders && orders.length > 0) {
            console.log('applyDiscountCode after order:', orders);
            orders.map((order) => {
                let originPromoCode = order.promoCode;
                this.getOrderManager(order.storeId).setDiscountCode('');

                let discounts = (order.discounts && order.discounts.length > 0 && order.discounts) || order.promotions;
                if (discounts && discounts.length > 0) {
                    for (let discount of discounts) {
                        if (discount.conditions && discount.conditions.length > 0) {
                            for (let condition of discount.conditions) {
                                if (condition.type && condition.type === 'code') {
                                    this.getOrderManager(order.storeId).setDiscountCode(originPromoCode); // clear promocode
                                    success = true;
                                }
                            }
                        }
                    }
                }
            });

        }
        return success;
    }





    getSumDiscount() {
        let orders = this.getOrders() || [];
        let total = 0;
        orders.length > 0 && orders.map((order) => {
            if (order.discount > 0) total += order.discount;
        });
        this.sumDiscount = Number(total.toFixed(2));
        return total;
    }
    initCourtDiscounts(discounts: Discount[], menusMap?) {
        let _courtDiscount  = [];
        discounts.forEach(d => {
            let promo = new Discount();
            promo.level = d.level;
            promo.benefits = [];

            if (d.benefits) {
                d.benefits.forEach(b => {
                    // if (menu && b.type === 'unlock' && menu.unlocks) promo.unlocks = menu.unlocks;
                    // if (menu && b.type === 'gift' && menu.gifts) promo.gifts = menu.gifts;
                    if (menusMap && Object.keys(menusMap).length > 0) {
                        if (d.brandIds && d.brandIds.length > 0) {
                            d.brandIds.map((id) => {
                                if (menusMap[id]) {
                                    if ( b.type === 'unlock' && menusMap[id].unlocks) promo.unlocks = menusMap[id].unlocks;
                                    if ( b.type === 'gift' && menusMap[id].gifts) promo.gifts = menusMap[id].gifts;
                                }
                            });
                        }
                    }
                     promo.benefits.push(Benefit.cloneBenefit(b));
                });
            }
            promo.conditions = [];
            if (d.conditions) {
                d.conditions.forEach(c => {

                    promo.conditions.push(Condition.cloneCondition(c));
                });
            }
            promo.name = d.name;
            promo.amount = d.amount;
            promo.available = d.available;
            promo.backendId = d.backendId;
            promo.brandIds = d.brandIds;
            promo.id = d.id;
            promo.enabled = d.enabled;
            promo.desc = d.desc;
            promo.quantity = d.quantity;
            promo.alone = d.alone;
            promo.priority = d.priority;
            promo.disabledChannels = d.disabledChannels;
            promo.group = d.group;
            // if (promo.id === 'fc3f8289-da99-4f48-9169-d84c9e91e285') {

            // }

            _courtDiscount.push(promo);
        });
        return _courtDiscount;
    }
    getAvailableDiscount(discounts: Discount[], orderbrandId, order?) { //  promoCode 的discount不需要用brandids去 filter, 它直接作用与court
        let availableDiscounts = [];
        this.typeAllDiscount = null;
        discounts.map((discount) => {
            if (!discount.brandIds || discount.brandIds.length === 0 ) { // 未set brandIds条件,即改discount适用与all brand
                if (this.typeAllDiscount) return;
                if (discount.conditions.length > 0) { // promocode discont
                    discount.conditions.map((c) => {
                        if ( c.type === 'all' ) {
                            this.typeAllDiscount = discount;
                        } else if (c.type === 'onetime') {
                            if (this.checkOneTimeDiscount(discount)) {
                                this.typeAllDiscount = discount;
                            }
                        }
                    });
                }

            } else {
                discount.brandIds.map((brandId) => {
                    brandId === orderbrandId && availableDiscounts.push(discount);
                });
            }
        });
        return availableDiscounts;
    }
    getPromoAmount(): any {
        if (this.promoAmount > 0) {
            let orders = this.getOrders();
            let othersDiscountsAmount = 0; // 其它discount的价钱
            orders && orders.length > 0 && orders.map((o) => {
                othersDiscountsAmount += o.discount || 0;
            });
            return this.promoAmount - othersDiscountsAmount;
        } else {
            return this.promoAmount;
        }

    }
    isApiDiscount(discount): boolean {
        // Custom: discountCode 和 condition.type === 'all' 都算系promoDiscount
        if (!discount) return false;
        let is = false;
        if (discount.conditions.length > 0) { // promocode discont
            discount.conditions.map((d) => {
                if (d.type === 'code' || d.type === 'all') {
                    is = true;
                }
            });
        }
        return is;
    }

    checkOneTimeDiscount(discount) { // IsSatisfyed
        if (!discount || !discount.conditions) return;
        let ok = false;
        discount.conditions.map((c) => {
            if (c.type === 'membertype' && c.types && this.authService.checkHasAAMember()) {
                let type = this.authService.checkIsStaff() ? 'staff' : 'member';
                if (c.types.indexOf(type) > -1) {
                    ok = true;
                }
            }
        });
        return ok;
    }

    getOrderManager(storeId: number | string): OrderManager {
      return  this.orderManagerMap[storeId];
    }

    getOrderManagers(): OrderManager[] {
        let orderManager: OrderManager[] = [];
        for (let key in this.orderManagerMap) {
            orderManager.push(this.orderManagerMap[key]);
        }
        return orderManager;
    }

    getOrders(): Order[] {
        let orders: Order[] = [];
        for (let key in this.orderManagerMap) {
            if (this.orderManagerMap[key].order.grandTotal || (this.orderManagerMap[key].order && this.orderManagerMap[key].order.orderitems.length > 0)) {
                orders.push(this.orderManagerMap[key].order);

            }
        }
        return orders;
    }

    getStores(): Store[] {
        let stores: Store[] = [];
        for (let key in this.orderManagerMap) {
            if (this.orderManagerMap[key].order.grandTotal || (this.orderManagerMap[key].order && this.orderManagerMap[key].order.orderitems.length > 0)) {
                stores.push(this.orderManagerMap[key].store);

            }
        }
        return stores;
    }

    getStore(storeId: number): Store {
        return this.orderManagerMap[storeId].store;
    }

    clearAll() {
        this.orderManagerMap = {};
        this.currentPeriods = {};
    }

    deleteOrderManager(storeId: number) {
       delete this.orderManagerMap[storeId];
    }

    getOrderCount(): number {
        let orderManagers = this.getOrderManagers();
        let sum = 0;
        orderManagers.forEach((orderManager) => {
            sum += orderManager.count;
        });
        return sum;
    }

    getOrderTotal(): number {
        let orderManagers = this.getOrderManagers();
        let sum = 0;
        orderManagers.forEach((orderManager) => {
            sum = Number((sum + orderManager.order.total).toFixed(2));
        });
        return sum;
    }

    getOrderGrandTotal(notShowDiscountTotal =  false): number {
        let orderManagers = this.getOrderManagers();
        let sum = 0;
        orderManagers.forEach((orderManager) => {
            let discount = 0;
            if (notShowDiscountTotal && orderManager.order.discount > 0 ) {
                discount = orderManager.order.discount;
            }
            sum = Number((sum + orderManager.order.grandTotal + discount).toFixed(2));
        });
        return sum;
    }
    getOrderitemsTotal(): number {
        let orderManagers = this.getOrderManagers();
        let sum = 0;
        orderManagers.forEach((orderManager) => {
            sum += orderManager.count;
        });
        return sum;
    }
    setStoreCurrentOrderingPeriod(storeId: number, period: Period) {
        this.currentPeriods[storeId] = period;
    }

    deleteStoreCurrentOrderingPeriod(storeId: number) {
        delete this.currentPeriods[storeId];
    }

    getStoresCurrentOrderingPeriod(): [Period[], number[]] {
        let periods: Period[] = [];
        let storeIds: number[] = [];
        for (let key in this.currentPeriods) {
            periods.push(this.currentPeriods[key]);
            storeIds.push(Number(key));
        }
        return [periods, storeIds];
    }



}
