
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { EN_JSON } from "../../../assets/i18n/en";
import { ZH_CN_JSON } from "../../../assets/i18n/zh-cn";
import { ZH_JSON } from "../../../assets/i18n/zh";
@Injectable({ providedIn: 'root' })
export class JsonService {

    private json = null;
    public currentLang = 'en';
    private templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;
    constructor(private http: HttpClient, public t: TranslateService) {

    }

    public getJson() {
        return this.json;
    }

    public use(language: string) {

        // if (this.currentLang !== language) {
        //     this.clearJson();
        // }
        this.currentLang = language;

        if (!language) language = 'en';
        
        this.getTranslateJson(language);
        // this.getTranslateJson(language).subscribe(v => {
        //     console.log('JJ: JsonService -> use -> v', v);
        // });
    }

    /**
     *
     * @param key :string
     * @param params object: {key:value}
     */
    public translate(key: string, params?: any): string {
        if (!key) return '';
        if (!this.json) return key;
        let key_ = key;
        if (!key_.includes('.')) {
            let r = this.parseParams(this.json[key_], params);
            if (r == "") {
                return r
            }
            return (r || key);
        }
        let keys = key_.split('.');
        let json_: any = this.json;
        for (let key of keys) {
            json_ = json_[key];
            if (!json_) break;
        }
        let r = this.parseParams(json_, params);
        if (r == "") {
            return r
        }
        return (r || key);

    }
    // /**
    //  *
    //  * @param key :string
    //  * @param params object: {key:value}
    //  */
    // public translate(key: string, params?: any): string {
    //     if (!key) return '';
    //     if (!this.json) return this.t.instant(key, params);
    //     let key_ = key;
    //     if (!key_.includes('.')) {
    //         return (this.parseParams(this.json[key_], params) || this.t.instant(key, params));
    //     }
    //     let keys = key_.split('.');
    //     let json_: any = this.json;
    //     for (let key of keys) {
    //         json_ = json_[key];
    //         if (!json_) break;
    //     }

    //     return (this.parseParams(json_, params) || this.t.instant(key, params));

    // }

    /**
     *
     * @param language : en/ zh / zh-cn
     */
    public getTranslateJson(language: string = 'en'): any {
        language = language.toLowerCase();

        let json = {};
        switch (language) {
            case "en":
                json = EN_JSON();
                break;
            case "zh":
                json = ZH_JSON();
                break;
            case "zh-cn":
            case "zh_cn":
                json = ZH_CN_JSON();
            default:
                console.log("missing match");
                break;
        }
        this.json = json;
        console.log("JJ: JsonService -> getTranslateJson -> this.json", this.json)
        return json;
    }

    private parseParams(expr: string, params?: any): string {
        if (!params || !expr) {
            return expr;
        }
        // expr: 'Choose at most {{max}} - {{min}}'
        // {max:23,min:10}
        // return: Choose at most 23 - 10
        return expr.replace(this.templateMatcher, (substring: string, b: string) => {
            // substring: {{max}}
            // b : max
            // substring: {{min}}
            // b: min
            // console.log('fdsfd:', substring, b, params[b]);
            return params[b].toString() || substring;
        });
    }

    /**
    * @param o1 Object or value to compare.
    * @param o2 Object or value to compare.
    * @returns true if arguments are equal.
    */
    private equals(o1: any, o2: any): boolean {
        if (o1 === o2) return true;
        if (o1 === null || o2 === null) return false;
        if (o1 !== o1 && o2 !== o2) return true; // NaN === NaN
        let t1 = typeof o1, t2 = typeof o2, length: number, key: any, keySet: any;
        if (t1 === t2 && t1 === 'object') {
            if (Array.isArray(o1)) {
                if (!Array.isArray(o2)) return false;
                if ((length = o1.length) === o2.length) {
                    for (key = 0; key < length; key++) {
                        if (!this.equals(o1[key], o2[key])) return false;
                    }
                    return true;
                }
            } else {
                if (Array.isArray(o2)) {
                    return false;
                }
                keySet = Object.create(null);
                for (key in o1) {
                    if (!this.equals(o1[key], o2[key])) {
                        return false;
                    }
                    keySet[key] = true;
                }
                for (key in o2) {
                    if (!(key in keySet) && typeof o2[key] !== 'undefined') {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }

    private clearJson() {
        this.json = null;
    }

}
