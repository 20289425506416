import {Component, EventEmitter, Input, OnInit, Output, Injector} from '@angular/core';
import {AlertController, } from '@ionic/angular';

import {OrderService} from '../../../core/services/order.service';
import {ConfigService} from '../../../core/services/config.service';
import {TranslateService} from '@ngx-translate/core';
import {MemberService} from '../../../core/services/member.service';
import {isEmailValid, isPhoneValid} from '../../utilities/checkFormatTool';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';

@Component({
    selector: 'app-guest-info',
    templateUrl: './guest-info.component.html',
    styleUrls: ['./guest-info.component.scss'],
})
export class GuestInfoComponent extends MobileBasePage implements OnInit {

    @Input()
    email: string;
    @Input()
    needEmail = true;
    @Input()
    showEmail = true;

    @Input()
    phone: string;
    @Input()
    needPhone = true;
    @Input()
    showPhone = true;

    @Output()
    emailChange = new EventEmitter<string>();

    @Output()
    phoneChange = new EventEmitter<string>();

    emailClicked = false;
    phoneClicked = false;

    
    constructor(private injector: Injector, public orderService: OrderService, public configService: ConfigService, public memberService: MemberService, public alertController: AlertController, public t: TranslateService) {
        super(injector);
    
    }

    ngOnInit() {
        this.phone = this.orderService.phone;
        this.email = this.orderService.email;
        // console.log('phone required?',this.needPhone, this.settings.phoneRequired);
        
    }

    emailChangeHandle() {
        console.log('on email change:', this.email);
        if (this.needEmail && !isEmailValid(this.email)) {
            this.emailClicked = true;
            this.orderService.email = null;

            const alert = this.alertController.create({
                header: this.t.instant('global.wrong-email-message'),
                buttons: [this.t.instant('buttons.ok')]
            });
            alert.then(data => data.present());
            return;
        } else if (isEmailValid(this.email)) {
            this.configService.putPreference('aigens-email', this.email);
            this.orderService.email = this.email;
        }
        this.emailChange.emit();
    }


    phoneChangeHandle() {
        console.log('on componenet phone change:', this.phone);
        if (this.needPhone && !isPhoneValid(this.phone, this.memberService.country)) {
            this.phoneClicked = true;
            this.orderService.phone = null;

            const alert = this.alertController.create({
                header: this.t.instant('global.wrong-phone-message'),
                buttons: [this.t.instant('buttons.ok')]
            });
            alert.then(data => data.present());
            return;
        } else if (isPhoneValid(this.phone, this.memberService.country)) {
            this.configService.putPreference('aigens-phone', this.phone);
            this.orderService.phone = this.phone;
        }
        this.phoneChange.emit();
    }
}
