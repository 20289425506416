
import { Pipe, PipeTransform } from '@angular/core';
import { JsonService } from 'src/app/core/services/json.service';

@Pipe({
    name: 'translatej',
    // pure: true
})
// params 要在ts 中定义好
// {{"pages.terms-conditions.agree-text"|translatej :j:params}}
export class TranslateJPipe implements PipeTransform {
    transform(key: string, jsonService: JsonService, params?:any): string {

        if (!jsonService.translate) {
            console.warn("first param not JsonService");
            return key;
        }
        return jsonService.translate(key, params)
    }
}
