import {Injectable} from '@angular/core';
import {SdkBaseService} from './sdk-base-service';

@Injectable({providedIn: 'root'})
export class SdkApplepayService extends SdkBaseService {
    protected getServiceName() {
        return 'ApplepayService';
    }

    /**
     *
     * @param params
     * {
            items: [
                {
                    label: '3 x Basket Items',
                    amount: 49.99
                },
                {
                    label: 'Next Day Delivery',
                    amount: 3.99
                },
                {
                    label: 'My Fashion Company',
                    amount: 53.98
            }],

            shippingMethods: [
                {
                    identifier: 'NextDay',
                    label: 'NextDay',
                    detail: 'Arrives tomorrow by 5pm.',
                amount: 3.99
                },
                {
                    identifier: 'Standard',
                    label: 'Standard',
                    detail: 'Arrive by Friday.',
                    amount: 4.99
                },
                {
                    identifier: 'SaturdayDelivery',
                    label: 'Saturday',
                    detail: 'Arrive by 5pm this Saturday.',
                    amount: 6.99
            }],

            merchantIdentifier: 'merchant.apple.test',  // Need to pass?

            currencyCode: 'HKD',  /// CNY    HKD
            countryCode: 'HK',  ///  CN    HK
            billingAddressRequirement: 'none', // none/all/postcode/email/phone
            shippingAddressRequirement: 'none', // none/all/postcode/email/phone
            shippingType: 'shipping'  // shipping/delivery/store/service
        }
     *
     * @param callback :required
     *
     *   {
            "paymentData": "<BASE64 ENCODED TOKEN WILL APPEAR HERE>",
            "transactionIdentifier": "Simulated Identifier",
            "paymentMethodDisplayName": "MasterCard 1234",
            "paymentMethodNetwork": "MasterCard",
            "paymentMethodTypeCard": "credit",
            "billingEmailAddress": "",
            "billingSupplementarySubLocality": "",
            "billingNameFirst": "First",
            "billingNameMiddle": "",
            "billingNameLast": "NAME",
            "billingAddressStreet": "Street 1\n",
            "billingAddressCity": "London",
            "billingAddressState": "London",
            "billingPostalCode": "POST CODE",
            "billingCountry": "United Kingdom",
            "billingISOCountryCode": "gb",
            "shippingEmailAddress": "",
            "shippingPhoneNumber": "",
            "shippingNameFirst": "First",
            "shippingNameMiddle": "",
            "shippingNameLast": "Name",
            "shippingSupplementarySubLocality": "",
            "shippingAddressStreet": "Street Line 1\nStreet Line 2",
            "shippingAddressCity": "London",
            "shippingAddressState": "London",
            "shippingPostalCode": "POST CODE",
            "shippingCountry": "United Kingdom",
            "shippingISOCountryCode": "gb",
        }
     */
    public makePaymentRequest(params: any, callback) {
        if (!this.isAvailable()) { return; }

        this.callNative('makePaymentRequest', params, callback);
    }

    /**
     *
     * @param params :
     * { paymentAuthorizationStatus: "success" / "failure" / "invalid-billing-address" / "invalid-shipping-address" / "invalid-shipping-contact" / "require-pin" / "incorrect-pin" / "locked-pin"  }
     *
     *
     * @param callback ,optional
     */
    public completeLastTransaction(params: any, callback?) {
        if (!this.isAvailable()) { return; }
        this.callNative('completeLastTransaction', params, callback);
    }
}
