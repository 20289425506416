import {Data, Discount, Member, Menu, Offer, Order, OrderManager, Period, Spot, Store} from 'aigens-ng-core';
import {ConfigService} from './config.service';
import {AQuery} from '../base/aquery';
import {BaseService} from '../base/base-service';

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {MemberService} from './member.service';
import {TranslateService} from '@ngx-translate/core';
import {version} from '../../../../package.json';
import {ActivatedRoute} from '@angular/router';
import {CartService} from './cart.service';
import { AuthService } from '../auth/auth.service';
import { CouponService } from './coupon.service';
import { NavigationService } from './navigation.service';

@Injectable({providedIn: 'root'})

export class OrderService extends BaseService {
    aq: AQuery;

    // duplicate with orderManager
    // member: any = {};

    // openrice user data
    firstName: string;
    lastName: string;
    selectedType: string;
    email: string;
    countryCode: string;
    gender: string;
    address: any = {};
    phone: string;
    marketing = false;
    spot: string;
    session: string;
    pickUpTime: string;
    pickUpLabel: string;
    maxPickupTime: string;
    uuid: string;
    currency = 'HKD';
    country = 'HK';
    screenWidthUnit: number;
    selectedDineinTakeoutPrompt = 'firstView';
    sign = '$';
    courtId: number;

    // duplicate with orderManager
    mode = 'byod';
    menusMap:  any;
    /**
     *  mode option:
     *  dinein
     *  takeaway
     *  pickup: popup alert to select dinein/takeaway
     *
     *
     * */
    selfPickUp = false;
    delivery = false;
    takeout = false;
    isBK = false;
    type: string;
    // TODO: duplicate with orderManager
    store: Store;
    storeId: number;
    deviceId: string;
    currentPeriod: Period;
    hiddenNav = false;
    hideTotal = false;
    comeInHasSession = false;
    hasServiceChargeOrTax = false;
    isCourt = false;
    preOrder = false;
    chargeDesc: string;
    selectedReward: Offer;
    passcode;
    login = false;
    isStaff = false;
    isHomeButton = false;
    surveyUrl: string;
    surveyExtBrowser: boolean;
    orderExpire: number;
    discountCode: string;
    settings = {};
    promoCodeGift =  [];
    seatsGift = [];
    holdCouponId: string;
    seats: number = null;
    storesMap = {};
    constructor(private http: HttpClient, public cartService: CartService, public orderManager: OrderManager,
        public couponService: CouponService,
        public navigationService: NavigationService,
         public route: ActivatedRoute, public memberService: MemberService, public configs: ConfigService, public translate: TranslateService, public authService: AuthService) {
        super();

        this.aq = new AQuery(http, configs);




    }
    initLocalUsersData () {
          // 使用localstorage的 users data
          let member = this.configs.getUserData();
          console.log('OrderService -> initLocalUsersData -> getUserData', member);
          if (member && Object.keys(member).length !== 0) {
              // tslint:disable-next-line:forin
              for (let m in member) {
                  if (member[m]) this[m] = member[m];
              }
          }
          if (this.authService.checkHasAAMember()) {
              if ( this.authService.profileData['firstName']) this['firstName'] = this.authService.profileData['firstName'];
              if ( this.authService.profileData['lastName']) this['lastName'] = this.authService.profileData['lastName'];
              if ( this.authService.profileData['personEmail']) this['email'] = this.authService.profileData['personEmail'];
              if ( this.authService.profileData['phone']) this['phone'] = this.authService.profileData['phone'];
              if (this.authService.profileData['phoneNumberPrefix']) {
                  let phoneNumberPrefix = this.authService.profileData['phoneNumberPrefix'];
                  if (phoneNumberPrefix.indexOf('852') > 0) this['countryCode'] = '852';
                  if (phoneNumberPrefix.indexOf('86') > 0) this['countryCode'] = '852';
                  if (phoneNumberPrefix.indexOf('65') > 0) this['countryCode'] = '65';
                  if (phoneNumberPrefix.indexOf('853') > 0) this['countryCode'] = '853';
              }
              if (this.authService.profileData['personTitle']) {
                switch (this.authService.profileData['personTitle']) {
                    case 'Mr.':
                        this['gender'] = 'mr';
                        break;
                    case 'Mrs.':
                        this['gender'] = 'mrs';

                        break;
                    case 'Ms.':
                        this['gender'] = 'ms';

                        break;
                    case 'Miss':
                        this['gender'] = 'miss';

                        break;
                    default:
                        this['gender'] = 'mr';
                        break;
                }
            }
          }
    }
    get hasCrm(): boolean {
        const crm = (this.orderManager.store && this.orderManager.store.brand && this.orderManager.store.brand.crmId) || null;
        return !(!crm);
    }

    setStore(store: Store) {
        this.store = store;
        this.setCurrency(store.currency);
        const tmpSettings = store.menu && store.menu.settings ? store.menu.settings : null;
        if (tmpSettings) {
            this.settings[store.id] = tmpSettings;
        }

        this.hasServiceChargeOrTax = true; // store.menu && store.menu.settings ? (store.menu.settings.serviceCharge > 0 || store.menu.settings.tax > 0) : false;
        this.chargeDesc = store.menu && store.menu.settings ? store.menu.settings.chargeDesc : '';

        if (store.hasFeature('kiosk') && !this.spot && !this.preOrder) {
            this.type = 'prekiosk';
        } else if (store.hasFeature('byod')) {
            this.type = 'byod';
        }

        if (store && store.id) {
            this.storesMap[store.id] = store;
        }
    }
    setCurrency(currency: string) {
        if ('HKD' === currency) {
            this.sign = '$';
        } else if ('CNY' === currency) {
            this.sign = '¥';
        } else if ('SGD' === currency) {
            this.sign = 'S$';
        } else if ('MYR' === currency || 'RM' === currency) {
            this.sign = 'RM';
        } else if ('IDR' === currency) {
            this.sign = 'Rp';
        } else if (!currency) {
            currency = 'HKD';
            this.sign = '$';
        }

        this.currency = currency;

        if (this.store && this.store.currencySign) {
            this.sign = this.store.currencySign;
        }
        this.country = this.store.country;
    }
    getCurrentStoreSetting() {
        if (!this.store) {
            return null;
        }
        return this.settings[this.store.id];
    }

    clear() {
        this.pickUpLabel = null;
        this.pickUpTime = null;
        this.maxPickupTime = null;
        this.selectedReward = null;
        this.couponService.clear();
        this.holdCouponId = '';
    }
    clearPromoCodeGift() {
        this.promoCodeGift = [];
    }
    postOrder(order: Order): Observable<Order> {
        console.log('post');
        if (this.type === 'prekiosk') {
            return this.postPreKioskOrder(order);
        }

        if (this.type === 'byod') {
            return this.postByodOrder(order);
        }
    }

    postMultiOrder(orders: Order[], token: string): Observable<Order[]> {
        const url = '/api/v1/menu/orders.json?type=byod';


        console.log('orders', orders);

        const body = {};
        const headers = {};
        // orders.forEach((order) => {
        //     order.takeout = "takeaway" === this.orderManager.mode;
        // })
        body['orders'] = Order.toOrdersData(orders); // this.slimMultiOrder(orders);
        console.log('body', body);


        for (const order of body['orders']) {
            order['type'] = 'byod';
            order['session'] = this.session; // "1111";
            order['deviceId'] = this.session;
            order['takeout'] = 'takeaway' === this.orderManager.mode;
        }
        body['token'] = token;
        body['payment'] = 'stripe';
        body['email'] = this.email;
        if (this.pickUpTime === '0') {
            body['pickupTime'] = null;
        } else {
            body['pickupTime'] = Number(this.pickUpTime);
        }

        if (this.phone) {
            body['phone'] = this.phone;

        }


        console.log('body', body);

        const aq = this.aq;

        aq.url = url;
        aq.method = 'post';
        aq.body = body;

        return aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Order, jo['data'])));
    }

    postPreKioskOrder(order: Order): Observable<Order> {

        const url = '/api/v1/menu/order.json';
        // order.takeout = "takeaway" === this.orderManager.mode;
        const body = Order.toOrderData(order); // this.slimOrder(order);

        body['type'] = 'prekiosk';
        body['session'] = this.session;
        body['takeout'] = 'takeaway' === this.orderManager.mode;

        console.log('body', body);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;

        return aq.getJson().pipe(map(jo => Data.toData(Order, jo['data'])));

    }

    postByodOrder(order: Order): Observable<Order> {

        const url = '/api/v1/menu/order.json';
        // order.takeout = "takeaway" === this.orderManager.mode;
        const body = Order.toOrderData(order); // this.slimOrder(order);
        const headers = {};
        const member = this.memberService.getMember();


        if (this.cartService.enable) {
            const t = {
                uuid: this.memberService.uuid,
                name: this.memberService.name,
                lockTime: Date.now()
            };
            body['lock'] = t;
        }
        body['type'] = 'byod';
        body['session'] = this.session;
        body['spot'] = this.spot;
        body['deviceId'] = this.deviceId;
        body['takeout'] = 'takeaway' === this.orderManager.mode;
        body['pickUpTime'] = this.pickUpTime;
        if (this.passcode) {
            body['passcode'] = this.passcode;

        }


        console.log('body', body);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;
        if (member) {
            aq.auth(true);
        }


        return aq.getJson().pipe(map(jo => Data.toData(Order, jo['data'])));

    }

    postOrderWithPaymentObject(order: Order, paymentObject: any) {
        const url = '/api/v1/menu/order.json';


        let body = {};
        // order.takeout = "takeaway" === this.orderManager.mode;
        body = Order.toOrderData(order); // this.slimOrder(order);
        console.log('body', body);


        body['type'] = this.orderManager.mode;
        body['session'] = this.session; // "1111";
        body['storeId'] = order.storeId;
        body['deviceId'] = this.session;
        body['takeout'] = 'takeaway' === this.orderManager.mode;
        body['uuid'] = this.uuid;
        body['userToken'] = this.configs.getUserToken();


        // TODO: should use order.member
        const member = this.memberService.getMember();

        // if (this.isCourt || member) {
        //     body["member"] = this.member;
        //     if (member) {
        //         headers["sid"] = member.sessionId;
        //     }
        // }

        if (this.isCourt) {
            body['member'] = member;
        } else {
            if (member && this.hasCrm) {
                body['member'] = Member.toMemberData(member);

            }
        }

        if (this.email && this.email !== '') {
            body['email'] = this.email;

        } else {
            body['email'] = 'aigensemail@gmail.com';

        }

        if (this.pickUpTime) {
            body['pickupTime'] = this.pickUpTime;
        }

        if (this.phone) {
            body['phone'] = this.phone;

        }

        if (this.spot) {
            body['spot'] = this.spot;
        }

        body['charge'] = paymentObject;
        body['version'] = version;


        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;


        return aq.auth(true).getJson();
    }

    getBKUserInfo(sid): Observable<Member> {
        const host = window.location.host;
        const isPrd = host === 'bksg.order.place';
        const server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        const url = server + '/api/v1/bk/member.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';

        return aq.auth(true).getJson().pipe(map(jo => Data.toData(Member, jo['data'])));
    }

    postBKOrder(order: Order, paymentObject: any, isNetspay = false): Observable<Order> {
        const host = window.location.host;
        const isPrd = host === 'bksg.order.place';
        let server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';

        if (isNetspay) {
            server = isPrd ? 'https://bksgapp.appspot.com' : 'https://nets-dot-bksgtest.appspot.com'; // test nets pay
        }
        const url = server + '/api/v1/bk/order.json?menu=' + this.orderManager.mode;
        // order.takeout = "takeaway" === this.orderManager.mode;

        const body = Order.toOrderData(order); // this.slimOrder(order);
        // body["storeId"] = 5741421214564352;
        // body["type"] = "delivery";
        body['station'] = 'mobile';
        body['test'] = !isPrd; // when prd it should be false
        body['note'] = this.address['comment'];
        body['charge'] = paymentObject;
        // body["payment"] = "stripe";
        body['session'] = this.session;
        body['delivery'] = 'delivery' === this.orderManager.mode;
        // body["takeout"] = false;
        body['takeout'] = 'takeaway' === this.orderManager.mode;
        body['email'] = this.email.toLowerCase();

        if (this.pickUpTime && this.orderManager.mode === 'delivery') {
            body['deliveryTime'] = this.pickUpTime;
        } else if (this.pickUpTime && this.orderManager.mode !== 'delivery') {
            body['pickupTime'] = this.pickUpTime;
        }


        if (this.firstName && this.lastName) {
            body['firstName'] = this.firstName;
            body['lastName'] = this.lastName;
        }
        if (this.phone) {
            body['phone'] = this.phone;
        }

        this.address['city'] = 'Singapore';
        this.address['country'] = 'SG';
        body['address'] = this.address;

        console.log('body', body);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;

        return aq.getJson().pipe(map(jo => Data.toData(Order, jo['data'])));

    }

    getBKReward(): Observable<Offer[]> {
        const host = window.location.host;
        const isPrd = host === 'bksg.order.place';
        const server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        const url = server + '/api/v1/bk/reward/offer.json';
        const params = {};
        params['sid'] = this.session;


        console.log('params', params);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toDataArray(Offer, jo['data'])));

    }
    getECoupon(): Observable<any> {
        const url = '/api/v1/hkaa/offer.json?courtId=' + this.courtId;
        let token = localStorage.getItem('access_token') || null;
        // let token = '00DO00000053jxe!AQQAQLCElYm6IQaAv9c7Z2EiyXIbP8qfxippau.AieRuLZwf6sGv82cb7_Voe4Tt8OqvMamdgzQVrRkoiVo5QvcQfmR5w6L3';
        const params = {};
        token = token && token.replace(/"/g, '');
        params['courtId'] = this.courtId;
        params['token'] = this.base64Encode(token);
        console.warn('check offer',token);
        console.log('params', params);
        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toDataArray(Offer, jo['data'] || [])));

    }
    holdECoupon(id, crmId,  grandTotal, unhold = false): Observable<any> {
        let holdKey = unhold ?  'unhold' : 'hold';
        const url = `${'/api/v1/hkaa/offer.json?courtId=' + this.courtId + '&action=' + holdKey + '&crmId=' + crmId}`;
         let token = localStorage.getItem('access_token') || null;
        //  let token = '00DO00000053jxe!AQQAQNxLv00Qm4gG5Nd_U0EWuCtzqtrU8WItPyIyPKSizoozr.agYUp8IS4NG7cvFFvch3fwrVQdc6uX2Sh.oux5Ge.vX_3U'

         const params = {};
         token = token && token.replace(/"/g, '');
         params['token'] = this.base64Encode(token);
         let body = {};
         body['offers'] = this.couponService.selectedCoupons;
         body['grandTotal'] = grandTotal;
         body['id'] = id;

         console.log('params', params);
         const aq = this.aq;
         aq.url = url;
         aq.method = 'put';
         aq.body = body;
         aq.params = params;
        return aq.getJson().pipe(map(jo => jo['data']));
    }
    private base64Encode(input): string {
        let rv;
        rv = encodeURIComponent(input);
        rv = unescape(rv);
        rv = window.btoa(rv);
        return rv;
    }
    getReward(): Observable<Offer[]> {
        const url = '/api/v1/menu/offer.json?brandId=' + this.store.brandId + '&storeId=' + this.storeId;
        const member = this.memberService.getMember();


        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        if (member) {
            aq.auth(true);
        }
        return aq.getJson().pipe(map(jo => Data.toDataArray(Offer, jo['data'])));

    }

    checkOTP(passcode: string): Observable<boolean> {
        const url = '/api/v1/menu/spot.json'; // "/api/v1/store/otpverify.json?storeId=" + this.storeId +"&passcode=" + passcode;
        const params = {};
        params['action'] = 'verify';
        params['name'] = this.spot;
        params['storeId'] = this.storeId;
        params['passcode'] = passcode;

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo['vefified']));
    }

    getSpots(storeId: any): Observable<Spot[]> {

        const url = '/api/v1/menu/spot.json';

        const params = {};
        params['storeId'] = storeId;

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Spot, jo['data'])));
    }

    convertCoo() {
        const origin = window.location.origin;
        const url = `${origin}/convert/coo`
        const params = {};

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;
        return this.aq.auth(false).getJson();
    }

    checkSpot(storeId: any, spot: string, deviceId: string, code?: string): Observable<Spot[]> {

        const url = '/api/v1/menu/spot.json';

        const params = {};
        params['storeId'] = storeId;
        params['spot'] = spot;
        params['deviceId'] = deviceId;
        if (code) {
            params['code'] = code;

        }
        params['check'] = true;

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Spot, jo['data'])));
    }

    getByodOrders(storeId: any, spot: string, deviceId: string): Observable<Order[]> {
        const url = '/api/v1/menu/order.json';

        const params = {};
        params['session'] = this.session;

        if (spot) {

            params['spot'] = spot;
            if (deviceId) {
                params['deviceId'] = deviceId;
            }

        }

        params['type'] = this.orderManager.mode;
        params['storeId'] = storeId;


        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;


        return this.aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Order, jo['data'])));
    }

    getOrderWith(orderId: string, session: string) {
        const url = '/api/v1/menu/order/' + orderId + '.json';
        const params = {};
        params['session'] = session;
        params['fields'] = 'store,brand';
        params['locale'] = this.translate.currentLang;

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;
        return this.aq.auth(true).getJson().pipe(map(jo => Data.toData(Order, jo['data'])));
    }

    getOrders(session: string, partial: boolean = false, limitTo: number = 10, limitFrom: number = 0, since: number = null): Observable<Order[]> {
        const url = '/api/v1/menu/order.json';

        const params = {};
        params['session'] = session;
        params['fields'] = 'store,brand';
        params['locale'] = this.translate.currentLang;
        if (partial) {
            params['since'] = since;
            params['limitFrom'] = limitFrom;
            params['limitTo'] = limitTo;

        }


        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Order, jo['data']).filter((o) => !o['master'])));
    }

    getBKOrders(session: string): Observable<Order[]> {
        const host = window.location.host;
        const isPrd = host === 'bksg.order.place';
        const server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        const url = server + '/api/v1/menu/order.json';

        const params = {};
        params['session'] = session;
        // params['fields'] = "store,brand";
        params['locale'] = this.translate.currentLang;

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.auth(true).getJson().pipe(map(jo => Data.toDataArray(Order, jo['data'])));
    }

    getOrderBySession(id: string, session: string): Observable<Order> {

        const url = '/api/v1/menu/order/' + id + '.json';
        const params = {};
        params['session'] = session;

        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;

        return this.aq.getJson().pipe(map(jo => Data.toData(Order, jo['data'])));
    }

    getBKStore(postal: number): Observable<Store[]> {
        const host = window.location.host;
        const isPrd = host === 'bksg.order.place';
        const server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        const url = server + '/api/v1/bk/store.json?postal=' + postal;

        const aq = this.aq;
        aq.url = url;
        return aq.getJson().pipe(map(jo => {
            this.address.latitude = jo['data']['latitude'];
            this.address.longitude = jo['data']['longitude'];

            return Data.toDataArray(Store, jo['data']['stores']);
        }));
    }
    getApplyedPromoDiscount(orders: Order[], typeAllDiscount?: Discount ) {
        const url = '/api/v1/menu/offer/validate.json?courtId=' + this.courtId;
        const body = {};
        const headers = {};
        if (this.pickUpTime === '0') {
            body['pickupTime'] = null;
        } else {
            let pickUpTime: number = Number(this.pickUpTime);
            if (this.pickUpTime.length < 11) {
                pickUpTime = Number(this.pickUpTime);
            }
            body['pickupTime'] = pickUpTime;
        }


        let promoCodes = [];

        typeAllDiscount && promoCodes.push(typeAllDiscount.backendId);
        this.discountCode &&   promoCodes.push( this.discountCode);
        body['promoCodes'] = promoCodes;

        if (this.phone) {
            body['phone'] = this.phone;
        }
        if (this.email && this.email !== '') {
            body['email'] = this.email;
        }
        body['orders'] = Order.toOrdersData(orders); // this.slimMultiOrder(orders);

        let memberCode;
        let member = {
            lastName: this.lastName,
            firstName: this.firstName,
            countryCode: this.countryCode,
            phone: this.phone,
            email: this.email,
            gender: this.gender,
            salutation: this.translate.instant(`global.${this.gender}`)
        };

        if (this.authService.checkHasAAMember()) {
             // if login. no need pass matketing file
            member['type'] =  this.authService.checkIsStaff() ? 'staff' : 'member';
            memberCode = this.authService.profileData['memberNumber'] || null;
            if (memberCode) member['backendId'] = memberCode;
        } else {
            member['type'] = 'guest';
            member['marketing'] = this.marketing;
        }

        for (const order of body['orders']) {
            order['type'] = this.mode;
            order['session'] = this.session;
            order['deviceId'] = this.session;
            order['takeout'] = this.mode === 'takeaway';
            order['member'] = member;
            if (memberCode) order['memberCode'] = memberCode;
        }

        body['version'] = version;
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;


        return aq.getJson();
    }
    postMultiOrderWithPaymentObject(orders: Order[], paymentObject: any, couponsId?): Observable<Object> {
        // var url = "https://payment-dot-aigensstoretest.appspot.com/api/v1/menu/orders.json";
        // const url = '/api/v1/menu/orders.json?type=' + this.mode;
        let memberCode;
        const url = '/api/v1/menu/orders.json?type=' + 'court' + '&courtId=' + this.courtId + '&locale=' + this.translate.currentLang;

        let member = {
            lastName: this.lastName,
            firstName: this.firstName,
            countryCode: this.countryCode,
            phone: this.phone,
            email: this.email,
            gender: this.gender,
            salutation: this.translate.instant(`global.${this.gender}`)
        };
        if (this.authService.checkHasAAMember()) {
             // if login. no need pass matketing file
            member['type'] =  this.authService.checkIsStaff() ? 'staff' : 'member';
            memberCode = this.authService.profileData['memberNumber'] || null;
            if (memberCode) member['backendId'] = memberCode;
        } else {
            member['type'] = 'guest';
            member['marketing'] = this.marketing;
        }

        this.configs.setLocal('member', member); // 将用户信息储存在本地

        const body = {};
        const headers = {};
        body['orders'] = Order.toOrdersData(orders); // this.slimMultiOrder(orders);
        if (this.discountCode) {
            body['promoCodes'] = [this.discountCode];
        }
        body['uuid'] = couponsId;
        body['id'] = couponsId;
        body['deviceId'] = this.session;
        for (const order of body['orders']) {
            order['type'] = this.mode;
            order['session'] = this.session;
            order['deviceId'] = this.session;
            order['takeout'] = this.mode === 'takeaway';
            order['member'] = member;
            order['trackerUrl'] =  window.location.origin + '/' + this.navigationService.getRootPageUrl()
            + '/summary-list/' + this.session + '?locale=' + this.translate.currentLang;
            if (memberCode) order['memberCode'] = memberCode;
        }

        if (this.pickUpTime === '0') {
            body['pickupTime'] = null;
        } else {
            let createTime = new Date().getTime();
            let pickUpTime: number = Number(this.pickUpTime);
            if(pickUpTime && pickUpTime < createTime){
                pickUpTime = null;
            }

            if (this.pickUpTime.length < 11) {
                pickUpTime = Number(this.pickUpTime);
            }
           
            body['pickupTime'] = pickUpTime;
        }
        if (this.couponService.selectedCoupons && this.couponService.selectedCoupons.length > 0) {
            body['offers'] = this.couponService.selectedCoupons;
        }
        if (this.phone) {
            body['countryCode'] = this.countryCode;
            body['phone'] = this.phone;
        }
        if (this.email && this.email !== '') {
            body['email'] = this.email;
        }

        body['charge'] = paymentObject;
        body['version'] = version;

        console.log('body', body);
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;


        return aq.getJson();

    }

    orderChangeStatus(status: string, orderId: string): Observable<Order> {
        const url = '/api/v1/menu/order/' + orderId + '.json';
        const params = {};
        params['status'] = status;

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toData(Order, jo['data'])));

    }

    verify(orderId: string): Observable<any> {
        const url = '/api/v1/bk/order/pickup.json';
        const aq = this.aq;
        aq.url = url;
        aq.shouldAuth = true;
        aq.method = 'post';
        // var params = { "orderId": orderId, 'passcode': passcode };
        const params = {'orderId': orderId};
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo['data']));
    }
    checkingMPGSStatus(id: string, paymentId: string, action?: string) {
        const url = '/api/v1/pay/detail.json';
        const params = {};
        params['orderId'] = id;
        params['payment'] = paymentId;

        if (action) {
            params['action'] = action;
        }
        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;

        return aq.getJson();
    }

    checkingOrderStatus(id: string, paymentId: string, action?: string) {
        const url = '/api/v1/pay/success.json';
        const params = {};
        params['orderId'] = id;
        params['payment'] = paymentId;

        if (action) {
            params['action'] = action;
        }
        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;

        return aq.getJson();
    }

    checkingOrderStatusWithUrl(url: string) {
        const tmpUrl = url ? url : '';

        const aq = this.aq;
        aq.url = tmpUrl;
        aq.method = 'get';

        return aq.getJson();
    }


    // 从v1搬过来的code
    editHeadCount(): Observable<any> {
        let url = '/api/v1/menu/spot.json';

        let params = {};
        params['name'] = this.spot;
        params['seats'] = this.seats;
        params['key'] = this.session;
        params['action'] = 'edit';
        params['storeId'] = this.store.id;
        let aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.params = params;

        return this.aq.auth(true).getJson().map(jo => Data.toDataArray(Order, jo['data']));

    }

}
