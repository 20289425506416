import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { Data, Store } from 'aigens-ng-core';
import { OrderService } from './order.service';
import { AuthService } from '../auth/auth.service';
export interface HKIADollarBalance {
  totalPoint: number;
  subPoints: {
    expireDate: string;
    availableBalance: number;
  }[];
  totalHKDollar: number;
  conversionRate: number;
}

export interface HKIADollarPassToOrder {
  hkiaDollarAmount : number,
  conversionRate : number,
  hkiaDollarPoint : number
}




@Injectable({
  providedIn: 'root'
})
export class HKIADollarService extends BaseService {
  aq: AQuery;
  hkiaBalances : HKIADollarBalance = {
    totalPoint: 0,
    subPoints: [],
    totalHKDollar: 0,
    conversionRate: 0   
  } ;

  hkiaPassToOrder : HKIADollarPassToOrder = {
    hkiaDollarAmount : 0,
    conversionRate : 0,
    hkiaDollarPoint : 0
  }

  public tempHKIADollarAmount : number = 0;
  
  finished: boolean = false;
  constructor(
    private http: HttpClient,
    public configs: ConfigService,
    private orderService: OrderService,
    private authService: AuthService
  ) {
    super();
    this.aq = new AQuery(http, configs);
  }

  ngOnInit() {
    
  }

  initGetHkiaBalances() {
    return new Promise((resolve, reject) => {
      this.getHkiaBalances().subscribe(data => {
          console.warn('check hkia balances', data);
          if (data) {
            this.hkiaBalances = data;
            this.finished = true;
            resolve(true);
          }else {
            reject({
              status : 1,
              error : 'Call HKIA Dollar API failed'
            });
          }
      },err => {
        console.warn('Error getting HKIA balances:', err);
        reject(err);
      });
    });
  } 
  
  get HkiaBalances() {
    return this.hkiaBalances;
  }

  /**
   * 获取会员的 HKIA Dollar 余额信息
   * @param memberNumber - 会员号码
   * @param token - 认证令牌
   * @param courtId - 商场ID
   * @returns Observable<HKIADollarBalance>
   */
  getHkiaBalances(): Observable<HKIADollarBalance> {
    const url = '/api/v1/crm/hkiadollar/member/balances.json';
    const courtId = this.orderService.courtId;
    const params = {};
    let token = localStorage.getItem('access_token') || null;
    token = token && token.replace(/"/g, '');
    token = this.base64Encode(token);

    // 解析存储的会员信息
    // const memberStr = localStorage.getItem('member');
    // const memberObj = memberStr ? JSON.parse(memberStr) : null;\

    const profileData = this.authService.profileData || {};
    
    params['memberNumber'] = profileData['memberNumber'] || null;
    params['token'] = token;
    params['courtId'] = courtId;
    this.aq.url = url;
    this.aq.params = params;
    this.aq.method = 'get';

    return this.aq.getJson().pipe(map((jo) => {
        return jo['data'];
    }));
  }
  private base64Encode(input): string {
    let rv;
    rv = encodeURIComponent(input);
    rv = unescape(rv);
    rv = window.btoa(rv);
    return rv;
  }
} 