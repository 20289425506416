import { Component, Injector, Input, OnInit, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';

@Component({
  selector: 'app-payment-method-modal',
  templateUrl: 'payment-method-modal.html',
  styleUrls: ['payment-method-modal.scss']
})
export class PaymentMethodModal extends MobileBasePage implements OnInit, AfterViewInit {
  @Input() buttons: any[];

  constructor(private modalCtrl: ModalController, public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    // ... 现有代码 ...
  }

  ngAfterViewInit() {
    // 等待DOM渲染完成后调整模态框高度
    setTimeout(() => {
      this.adjustModalHeight();
    }, 100);
  }

  private adjustModalHeight() {
    const modalWrapper = document.querySelector('.payment-method-modal .modal-wrapper') as HTMLElement;
    if (modalWrapper) {
      // 获取 payment-methods 元素
      const paymentMethods = modalWrapper.querySelector('.payment-methods') as HTMLElement;
      if (paymentMethods) {
        // 获取header高度
        const header = modalWrapper.querySelector('.list-header') as HTMLElement;
        const headerHeight = header ? header.offsetHeight : 0;
        
        // 获取payment-methods的实际高度
        const paymentMethodsHeight = paymentMethods.offsetHeight;
        
        // 计算总高度 = header高度 + payment-methods高度 + 额外padding(20px)
        const totalHeight = headerHeight + paymentMethodsHeight;
        
        // 设置最大高度为屏幕高度的90%
        const maxHeight = window.innerHeight * 0.9;
        const finalHeight = Math.min(totalHeight, maxHeight);
        
        // 设置模态框高度
        modalWrapper.style.height = `${finalHeight}px`;
      }
    }
  }

  selectPayment(button: any) {
    if (button.handler) {
      this.dismiss(button.handler);
    }else{
      this.dismiss();
    }
   
  }

  dismiss(handler?: any) {
    this.modalCtrl.dismiss(handler);
  }
} 