import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage} from 'angular-oauth2-oidc';
import {prdAuthConfig} from './prd-auth-config';
import {AuthGuardWithForcedLogin} from './auth-guard-with-forced-login.service';
import {AuthGuard} from './auth-guard.service';
import {authModuleConfig} from './auth-module-config';
import {AuthService} from './auth.service';
import {JsonpModule} from '@angular/http';
import {uatAuthConfig} from "./uat-auth-config";
import {internalAuthConfig} from "./internal-auth-config";

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
    return localStorage;
}

@NgModule({
    imports: [
        HttpClientModule,
        OAuthModule.forRoot(),
    ],
    providers: [
        AuthService,
        AuthGuard,
        AuthGuardWithForcedLogin
    ],
})
export class AuthModule {
    static checkIsAAPrd () {
        return window.location.host === 'mobile.hongkongairportfood.com';
    }

    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [

                // changbe oidc-config when deployhkaa
                // { provide: AuthConfig, useValue: internalAuthConfig},

                // {provide: AuthConfig, useValue: uatAuthConfig},
                { provide: AuthConfig, useValue: AuthModule.checkIsAAPrd() ? prdAuthConfig : uatAuthConfig},

                {provide: OAuthModuleConfig, useValue: authModuleConfig},
                {provide: OAuthStorage, useFactory: storageFactory},
            ]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
