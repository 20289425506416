import { Component, Injector, Input, OnInit, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';

@Component({
  selector: 'app-hkia-dollar-modal',
  templateUrl: 'hkia-dollar-modal.html',
  styleUrls: ['hkia-dollar-modal.scss']
})
export class HkiaDollarModal extends MobileBasePage implements OnInit, AfterViewInit {
  @Input() totalAmount: number;
  @Input() points: number;
  @Input() pointsValidityInfo: {availableBalance: number, expireDate: string}[];
  @Input() appliedHKIAAmount: number;
  @Input() conversionRate: number;
  @Input() editAmount : number = 0;
  inputAmount: string = '';
  isInsufficientPoints: boolean = false;
  // readonly POINTS_PER_DOLLAR: number = 50; // 50积分兑换1元
  availableAmount: number = 0; // 移除 @Input()
  errorMessage: string = '';
  isLoading: boolean = true;

  constructor(private modalCtrl: ModalController, public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    // 设置3秒后显示内容
    setTimeout(() => {
      this.isLoading = false;
      // 先检查积分是否足够兑换最小金额
      this.checkInitialPoints();
      this.calculateAvailableAmount();
      if(this.editAmount) {
        this.inputAmount = this.editAmount.toString();
      }
      // 在内容显示后再计算高度
      setTimeout(() => {
        this.adjustModalHeight();
      }, 100);
    }, 1000);
  }

  ngAfterViewInit() {
    // 先计算 loading 状态下的高度
    this.adjustLoadingModalHeight();
  }

  get saveConversionRate() {
    return this.conversionRate
  }
  get saveHkiaDollarAmount() {
    return this.inputAmount
  }
  get saveHkiaDollarPoints() {
    return Number(this.inputAmount) * this.conversionRate;
  }

  private adjustLoadingModalHeight() {
    const modalWrapper = document.querySelector('.hkia-dollar-modal .modal-wrapper') as HTMLElement;
    if (modalWrapper) {
      const header = modalWrapper.querySelector('.list-header') as HTMLElement;
      if (header) {
        // loading 状态下设置一个合适的高度，比如屏幕高度的 40%
        const loadingHeight = window.innerHeight * 0.4;
        const totalHeight = header.offsetHeight + loadingHeight;
        modalWrapper.style.height = `${totalHeight}px`;
      }
    }
  }

  private adjustModalHeight() {
    const modalWrapper = document.querySelector('.hkia-dollar-modal .modal-wrapper') as HTMLElement;
    if (modalWrapper) {
      // 获取主要内容区域
      const whiteBox = modalWrapper.querySelector('.white-box') as HTMLElement;
      const availableAmount = modalWrapper.querySelector('.available-amount') as HTMLElement;
      const header = modalWrapper.querySelector('.list-header') as HTMLElement;

      if (whiteBox && availableAmount && header) {
        // 计算所有内容的总高度
        const headerHeight = header.offsetHeight;
        const availableAmountHeight = availableAmount.offsetHeight;
        const whiteBoxHeight = whiteBox.offsetHeight;
        
        // 计算总高度
        const totalHeight = headerHeight + availableAmountHeight + whiteBoxHeight + 30;
        
        // 设置最大高度为屏幕高度的90%
        const maxHeight = window.innerHeight * 0.9;
        const finalHeight = Math.min(totalHeight, maxHeight);
        
        // 设置模态框高度并添加过渡效果
        modalWrapper.style.transition = 'height 0.3s ease-out';
        modalWrapper.style.height = `${finalHeight}px`;
      }
    }
  }

  checkInitialPoints() {
    // 如果积分小于兑换1元所需的积分，则显示积分不足
    this.isInsufficientPoints = this.points < this.conversionRate;
  }

  calculateAvailableAmount() {
    this.availableAmount = Math.floor(this.points / this.conversionRate);
  }

  getRequiredPoints(): number {
    return Number(this.inputAmount) * this.conversionRate;
  }

  onAmountChange() {
    const amount = Number(this.inputAmount);
    let errKey = '';
    if(amount < 0){
      errKey = 'negative'
     
    } else if (amount > this.totalAmount) {
      errKey = 'exceed-total';
    } else if (amount * this.conversionRate > this.points) {
      errKey = 'exceed-points';
    } else if (amount > this.availableAmount) {
      errKey = 'exceed-available';
    } 
    let key = `pages.dialog.hkia-dollar-modal.modal-error-msg-${errKey}`;
    console.warn('key',key);
    if(errKey){
      this.errorMessage = this.t.instant(`dialog.hkia-dollar-modal.modal-error-msg-${errKey}`);
    }else{
      this.errorMessage = '';
    }
  }

  numberOnly(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  isApplyDisabled(): boolean {
    return !this.inputAmount || 
           Number(this.inputAmount) > this.totalAmount || 
           (Number(this.inputAmount) * this.conversionRate) > this.points;
  }

  applyAmount() {
    if(this.inputAmount && !this.isInsufficientPoints && !this.errorMessage) {
      this.modalCtrl.dismiss({
        amount: Number(this.inputAmount)
      });
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
} 