import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

import * as Color from 'color';
import {Store} from 'aigens-ng-core';

const defaults = {
    primary: '#3880ff',
    secondary: '#0cd1e8',
    tertiary: '#7044ff',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    dark: '#222428',
    medium: '#989aa2',
    light: '#f4f5f8'
};

function contrast(color, ratio = 0.6) {
    color = Color(color);
    console.log('color', color);
    return color.isDark() ? color.darken(ratio) : color.lighten(ratio);
}

function CSSTextGenerator(colors) {
    colors = {...defaults, ...colors};

    const {
        primary,
        secondary,
        tertiary,
        success,
        warning,
        danger,
        dark,
        medium,
        light
    } = colors;

    const shadeRatio = 0.1;
    const tintRatio = 0.1;

    return `
    --ion-color-base: ${light};
    --ion-color-contrast: ${dark};

    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: 56,128,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade:  ${Color(primary).darken(shadeRatio)};

    --ion-color-warning: ${warning};
    --ion-color-warning-rgb: 56,128,255;
    --ion-color-warning-contrast: ${contrast(warning)};
    --ion-color-warning-contrast-rgb: 255,255,255;
    --ion-color-warning-shade:  ${Color(warning).darken(shadeRatio)};

    --ion-color-success: ${success};
    --ion-color-success-rgb: 56,128,255;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade:  ${Color(success).darken(shadeRatio)};

    --ion-color-danger: ${danger};
    --ion-color-danger-rgb: 56,128,255;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade:  ${Color(danger).darken(shadeRatio)};

    --ion-color-dark: ${dark};
    --ion-color-dark-rgb: 56,128,255;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade:  ${Color(dark).darken(shadeRatio)};

    --ion-color-medium: ${medium};
    --ion-color-medium-rgb: 56,128,255;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade:  ${Color(medium).darken(shadeRatio)};

    --ion-color-light: ${light};
    --ion-color-light-rgb: 56,128,255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade:  ${Color(light).darken(shadeRatio)};



    // omitted other styles, see full source code
`;
}

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    constructor(@Inject(DOCUMENT) private document: Document, ) {
        // storage.get('theme').then(cssText => {  // <--- GET SAVED THEME
        //     this.setGlobalCSS(cssText);
        // });
    }

    // Override all global variables with a new theme
    setStoreTheme(store: Store) {
        console.log('set theme', store.colors);
        // let overwrites = {};
        if (store.colors) {
            // let colors = store.colors;
            // // primary: store.colors['primary'],
            // //     light: store.colors['light'],
            // //     dark: store.colors['dark'],
            //  if(colors['primary'])
            //      overwrites['primary'] =
            const cssText = CSSTextGenerator(store.colors);
            this.setGlobalCSS(cssText);
        }

        // this.storage.set('theme', cssText); // <--- SAVE THEME HERE
    }

    setFavicon(iconUrl: string) {
        const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
        link['type'] = 'image/x-icon';
        link['rel'] = 'shortcut icon';
        link['href'] = iconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    setConfigTheme(colors: any) {
        console.log('set theme', colors);
        // let overwrites = {};
        if (colors) {
            // let colors = store.colors;
            // // primary: store.colors['primary'],
            // //     light: store.colors['light'],
            // //     dark: store.colors['dark'],
            //  if(colors['primary'])
            //      overwrites['primary'] =
            const cssText = CSSTextGenerator(colors);
            this.setGlobalCSS(cssText);
        }

        // this.storage.set('theme', cssText); // <--- SAVE THEME HERE
    }

    // Define a single CSS variable
    setVariable(name, value) {
        this.document.documentElement.style.setProperty(name, value);
    }

    private setGlobalCSS(css: string) {
        this.document.documentElement.style.cssText = css;
    }

}
