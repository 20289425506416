import {HttpClient} from '@angular/common/http';
import {MixTranslateLoader} from '../../core/mix-translate-loader';
import {ConfigService} from '../../core/services/config.service';
import {ThemeService} from '../../core/services/theme.service';

export function createTranslateLoader(http: HttpClient, configs: ConfigService, themeService: ThemeService) {
    return new MixTranslateLoader(http, configs, themeService);
}
export function getQueryVariable(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return '';
}

export function getQueryLocale() {
    let locale = this.getQueryVariable('locale');
    let langs = ['en', 'zh-cn', 'zh'];
    return locale && langs.indexOf(locale) > -1;
}