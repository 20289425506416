import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {AQuery} from '../base/aquery';
import {ConfigService} from '../services/config.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, ModalController} from '@ionic/angular';
import { CouponService } from '../services/coupon.service';
import { BrowserService } from './../services/browser.service';
import { NavigationService } from '../services/navigation.service';
import { Location } from '@angular/common';
import { resolve } from 'dns';

@Injectable({providedIn: 'root'})
export class AuthService {

    aq: AQuery;
    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
    private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
    public profileData = {};

    public myhkgaccesstoken:any;
    public myhkgrefreshtoken:any;
    public isFinish = false;
    /**
     * Publishes `true` if and only if (a) all the asynchronous initial
     * login calls have completed or errorred, and (b) the user ended up
     * being authenticated.
     *
     * In essence, it combines:
     *
     * - the latest known state of whether the user is authorized
     * - whether the ajax calls for initial log in have all been done
     */
    public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
        this.isAuthenticated$,
        this.isDoneLoading$
    ]).pipe(map(values => values.every(b => b)));

    private navigateToLoginPage() {
        // TODO: Remember current URL
        this.router.navigateByUrl('/should-login');
    }

    constructor(
        public oauthService: OAuthService,
        private router: Router,
        private http: HttpClient,
        public configs: ConfigService,
        public translate: TranslateService,
        public modalController: ModalController,
        public alertController: AlertController,
        public couponService: CouponService,
        public browserService: BrowserService, 
        public navigationService: NavigationService,
        public location: Location

    ) {
        this.aq = new AQuery(http, configs);
        // Useful for debugging:
        this.oauthService.events.subscribe(async (event) => {
            if (event instanceof OAuthErrorEvent) {
                console.error(event);
                if (event.type === 'invalid_nonce_in_state') {
                    console.warn('cannot validate the nonce');
                    let buttons = ['OK'];
                    const alert = await this.alertController.create({
                        header: 'invalid_nonce_in_state',
                        message: 'Listen Token Fail',
                        buttons: buttons
                    });
                    await alert.present();
                }
            } else {
                console.warn(event);
            }
        });

        // This is tricky, as it might cause race conditions (where access_token is set in another
        // tab before everything is said and done there.
        // TODO: Improve this setup.
        window.addEventListener('storage', (event) => {
            // The `key` is `null` if the event was caused by `.clear()`
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }

            console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

            if (!this.oauthService.hasValidAccessToken()) {
                this.navigateToLoginPage();
            }
        });

        this.oauthService.events
            .subscribe(_ => {
                this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
            });

        // this.oauthService.events
        //     .pipe(filter(e => ['token_received'].includes(e.type)))
        //     .subscribe(e => this.oauthService.loadUserProfile());
        //
        // this.oauthService.events
        //     .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
        //     .subscribe(e => this.navigateToLoginPage());

        // this.oauthService.setupAutomaticSilentRefresh();

    }
    checkIsAAPrd () {
        return window.location.host === 'mobile.hongkongairportfood.com';
    }
    public async getProfile() {
        try{
            let profile = await this.oauthService.loadUserProfile();
            if(profile['custom_attributes']['Remember_Me'] === 'false'){
                this.configs.clearCookieByValue('myhkgrefreshtoken');
            }
            console.warn('profile', profile);
            if(profile.sub){
                this.oauthService.setAppCookieValue("myhkgsalesforceid",profile.sub,4320);
            }
            if (!profile) return;
            
    
            let memberCompleted =  profile && profile['custom_attributes'] && profile['custom_attributes']['Member_Info_Completed'];
            if (memberCompleted && !(/^false$/i).test(memberCompleted)) {
                let url = this.checkIsAAPrd() ? `https://us-central1-aa-fos-mobile.cloudfunctions.net/app/profile/core/member/${profile.custom_attributes.External_ID}`
                    : `https://us-central1-aa-fos-uat-mobile.cloudfunctions.net/app/profile/core/member`;
                // const url = `https://us-central1-aa-fos-uat-mobile.cloudfunctions.net/app/profile/core/member`;
                // const url = `https://us-central1-aa-fos-mobile.cloudfunctions.net/app/profile/core/member/${profile.custom_attributes.External_ID}`;
                let aq = this.aq;
                aq.url = url;
                aq.method = 'get';
                return aq.getJson().pipe(map(jo => jo));
            } else {
                return Observable.throwError({status: 403});
            }
            // let  token = this.configs.getLocal('access_token');
    
            // changbe oidc-config when deployhkaa
        }catch(err){
            console.warn("check Auth getProfile erro ",err);
            //access token过期, 要用到refresh token
            return Observable.throwError({status: 401});
            
        }
       

    }

    public checkHasAAMember() {
        if(!this.profileData) return false;
        return this.hasValidToken() && Object.keys(this.profileData).length > 0;
    }
    public checkIsStaff(): boolean {
        let isStaff = false;
        if (this.checkHasAAMember()) {
            switch (this.profileData['staffStatus']) {
                case 'Approved': case 'To Be Expired': case 'To be expired':
                    isStaff = true;
                    break;
                case 'Pending': case 'Declined': case 'Expired':
                    isStaff = false;
                    break;
                default:
                    isStaff = false;
                    break;
            }
        }
        // return true;
        return isStaff;
    }
    public checkMemberType(): string {
        let type = 'default';
        if (this.checkHasAAMember()) {
            // if login. no need pass matketing file
            type = this.checkIsStaff() ? 'staff' : 'member';
       }
        return type;
    }
    public clearHKGStorageAndCookie(){
        this.configs.clearStorageAndCookieByArray(['redirectParams','myhkgrefreshtoken','myhkgaccesstoken','myhkgauthorization','myhkgsalesforceid','refresh_token','access_token_stored_at','access_token','id_token_claims_obj','granted_scopes'])
    }

    removeParamsFromUrl() {
        let url = this.location.path(); // 获取当前 URL
        let urlWithoutParams = url.split('?')[0]; // 获取不包含参数的 URL
    
        this.location.replaceState(urlWithoutParams); // 用不包含参数的 URL 替换当前 URL
    }

    public runInitialLoginSequence(): Promise<void> {
        if (location.hash) {
            console.log('Encountered hash fragment, plotting as table...');
            console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
        }

        // 0. LOAD CONFIG:
        // First we have to check to see how the IdServer is
        // currently configured:

        // changbe oidc-config when deployhkaa
        // return this.oauthService.loadDiscoveryDocument(window.location.origin + '/assets/config/internal-openid-configuration.json')
        // return this.oauthService.loadDiscoveryDocument(window.location.origin + '/assets/config/uat-openid-configuration.json')
        let res = this.checkIsAAPrd() ? this.oauthService.loadDiscoveryDocument(window.location.origin + '/assets/config/prd-openid-configuration.json')
        : this.oauthService.loadDiscoveryDocument(window.location.origin + '/assets/config/uat-openid-configuration.json');
            return res
            // 1. HASH LOGIN:
            // Try to log in via hash fragment after redirect back
            // from IdServer from initImplicitFlow:
            .then(async () => {
                console.warn("check ----- 11111111",location);
                //HKAAAPP 修改了登录的流程, 使用的是Redirect url , 所以会带有code之类的params回来 ,需要拿到之后重新tryLogin一次, 走LogincodeFlow.
                if(location.search){
                    console.warn("check ----- 333333",location);
                    await this.oauthService.tryLogin({
                        customHashFragment: location.search,
                        preventClearHashAfterLogin: true,
                        customRedirectUri: this.checkIsAAPrd() ? 'https://mobile.hongkongairportfood.com/silent-refresh.html' : 'https://uat-mobile.hongkongairportfood.com/silent-refresh.html',
                        disableOAuth2StateCheck: true,
                    }).catch((err) => {
                        console.warn("check tryLogin faile",err);
                        this.clearHKGStorageAndCookie();
                    });

                    this.removeParamsFromUrl();
                    return Promise.resolve();
                }else{
                    console.warn("check ----- 444",location);
                    this.oauthService.tryLogin({disableOAuth2StateCheck: true})
                }
              
            }).then((res) => {
                // 睇清楚这里
                if (this.oauthService.hasValidAccessToken()) {
                    return Promise.resolve();
                }

                if (window.location.href.indexOf('access_token') === -1 && !this.configs.getLocal('access_token')) {
                    return;
                }
                // 2. SILENT LOGIN:
                // Try to log in via a refresh because then we can prevent
                // needing to redirect the user:
                return this.oauthService.silentRefresh()
                    .then(() => Promise.resolve())
                    .catch(result => {
                        // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                        // Only the ones where it's reasonably sure that sending the
                        // user to the IdServer will help.
                        const errorResponsesRequiringUserInteraction = [
                            'interaction_required',
                            'login_required',
                            'account_selection_required',
                            'consent_required',
                        ];

                        if (result
                            && result.reason
                            && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

                            // 3. ASK FOR LOGIN:
                            // At this point we know for sure that we have to ask the
                            // user to log in, so we redirect them to the IdServer to
                            // enter credentials.
                            //
                            // Enable this to ALWAYS force a user to login.
                            // this.oauthService.initImplicitFlow();
                            //
                            // Instead, we'll now do this:
                            console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
                            return Promise.resolve();
                        }

                        // We can't handle the truth, just pass on the problem to the
                        // next handler.
                        return Promise.reject(result);
                    });
            })

            .then(() => {
                this.isDoneLoadingSubject$.next(true);

                // Check for the strings 'undefined' and 'null' just to be sure. Our current
                // login(...) should never have this, but in case someone ever calls
                // initImplicitFlow(undefined | null) this could happen.
                if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
                    let stateUrl = this.oauthService.state;
                    if (stateUrl.startsWith('/') === false) {
                        stateUrl = decodeURIComponent(stateUrl);
                    }
                    console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
                    this.router.navigateByUrl(stateUrl);
                }
            }).catch(() => this.isDoneLoadingSubject$.next(true));
            
    }

    public setAppCookieValue(name:string,value:string,expiredHours:number):any{
        console.log('check setAppCookieValue',value.replace(/"/g, ''));
        this.oauthService.setAppCookieValue(name,value.replace(/"/g, ''),expiredHours);
    }

    public getAppCookieValue(name:string):any{
        return this.oauthService.getAppCookieValue(name);
    }

    public removeAppCookieValue(){

    }

    public async login(targetUrl?: string) {
        
        // this.getAppCookieValue();
        this.oauthService.resetImplicitFlow();
        // Note: before version 9.1.0 of the library you needed to
        // call encodeURIComponent on the argument to the method.
        // login in same tab
        // this.http.get('https://us-central1-aa-fos-uat-mobile.cloudfunctions.net/app/checkapp').subscribe(data => {
        //     console.log('data', data);
        //     const isApp = data && data['isApp'];
        //     return isApp ? this.oauthService.initLoginFlow() : this.oauthService.initLoginFlowInPopup({
        //         height: 400,
        //         width: 800
        //     });
        // }, error => {
        //     console.log('error', error);
        //     return this.oauthService.initLoginFlow();
        // })
        // return this.oauthService.initLoginFlow();
        // login in new tab
        let lang = 'en_US';
        switch (this.translate.currentLang) {
            case 'zh':
                lang = 'zh_HK';
                break;
            case 'zh-cn':
                lang = 'zh_CN';
                break;
            case 'en':
            default:
                break;
        }
        window.localStorage.setItem('MP_SELECTED_LANGUAGE', lang);


        let isWechat = this.browserService.isWeChat();
        let isAlipay = this.browserService.isAlipay();
        let isAlipayHK = this.browserService.isAlipayHK();
        let loginHint = {
            'login_hint' : lang + this.getAppLoginHint()
        }
        if (isWechat || isAlipay || isAlipayHK) {
            return this.oauthService.initLoginFlow(null,loginHint);
        } else {
            return this.oauthService.initLoginFlowInPopup({height: 400, width: 800}, loginHint);
        }

        // let temp = await this.modalController.create({
        //     component: LoginModal
        // });
        // await temp.present();
        // return temp.onDidDismiss
    }
    public logout() {

        console.log('AuthService -> logout -> this.router.url', );
        console.log('AuthService -> logout -> this.oauthService.logoutUrl', this.oauthService.logoutUrl);
        this.couponService.clear();
        let headers = new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        );
        let params = new HttpParams();
        let revokationParams = params
            .set('token', this.oauthService.getAccessToken());
        console.log('please revoke', this.oauthService.getAccessToken());
        // this.oauthService.logOut(false);
        // window.location.href = 'https://hkairportloyalty--sit.sandbox.my.site.com/hkairportrewards/services/auth/idp/oidc/logout'
        // this.oauthService.revokeTokenAndLogout({cb: window.location.href}, true)
        this.http.post(this.oauthService.revocationEndpoint, revokationParams, {headers}).subscribe(async response => {
            // await this.MyHkgLogout(revokationParams,headers);
            console.log('revoke token', response);
            this.clearHKGStorageAndCookie();
            this.oauthService.logOut({cb: `${window.location.origin}/court/5726866849660928`});
            //  +  this.navigationService.getRootPageUrl()});
        }, error => {
            console.error('revoke token error', error);
            this.oauthService.logOut({cb: `${window.location.origin}/court/5726866849660928`});
            // this.navigationService.getRootPageUrl()});
        });
        // this.oauthService.revokeTokenAndLogout().then(r => console.log('r',r));
        // this.http.get(this.oauthService.logoutUrl).subscribe(response => {
        //     console.log('logout', response);
        // })

        // window.open(this.logoutUrl);
    }

    MyHkgLogout(revokationParams,headers){
       try{
            return new Promise((resolve, reject) => {
                if (!this.configs.isInHkaaApp()) {
                    resolve(true);
                } else {
                    this.http.post(this.oauthService.hkg_logout_endpoint, revokationParams, { headers }).subscribe(
                        (response) => {
                            resolve(response);
                        },
                        (error) => {
                            reject(error);
                        }
                    );
                }
            });
       }catch(err){
         
       }
       
    }

    public load() {
        this.oauthService.loadUserProfile().then(data => {
            console.log('profile', data);
        }).catch(error => console.warn(error));
    }

    public refresh() {
        this.oauthService.silentRefresh();
    }

    public hasValidToken() {
        return this.oauthService.hasValidAccessToken();
    }

    public  refreshTokenFromApp(){
        // alert(`refresh token flow`)
        return new Promise(async (resolve,reject)=>{
            try{
                let tokenResponse = await this.oauthService.refreshToken();
                console.warn("refreshTokenFromApp tokenResponse",tokenResponse);
                if(tokenResponse){
                    console.warn("refresh token 成功");
                    resolve(tokenResponse);
                }
            }catch(err){
                console.warn("refreshTokenFromApp err",err);
                this.clearHKGStorageAndCookie();
                reject()
            }
        });
       
        
        
    }

    //this.function is for getAppCookie expired time.

    public returnAppCookieExpiredTime(name:string):any{
        return this.oauthService.returnAppCookieExpiredTime(name);
    }

    public checkAppCookieExpired(name:string){
        return this.oauthService.checkAppCookieExpired(name);
    }

    // These normally won't be exposed from a service like this, but
    // for debugging it makes sense.
    public get accessToken() {
        return this.oauthService.getAccessToken();
    }

    public get refreshToken() {
        return this.oauthService.getRefreshToken();
    }

    public get identityClaims() {
        return this.oauthService.getIdentityClaims();
    }

    public get idToken() {
        return this.oauthService.getIdToken();
    }

    public get logoutUrl() {
        return this.oauthService.logoutUrl;
    }

    // TODO: please update the target path you gonna use.
    onIncompleteProfile() {
        let lang = 'en_US';
        switch (this.translate.currentLang) {
            case 'zh':
                lang = 'zh_HK';
                break;
            case 'zh-cn':
                lang = 'zh_CN';
                break;
            case 'en':
            default:
                break;
        }
        window.localStorage.setItem('MP_SELECTED_LANGUAGE', lang);
        const DEV_PROFILE = `https://hkia.mp.acnhk.com/${lang}/account/incomplete?cb=`;
        const UAT_PROFILE = `https://hkia.mp.sit.acnhk.com/${lang}/account/incomplete?cb=`;
        const PRD_PROFILE = `https://hkairportrewards.com/${lang}/account/incomplete?cb=`;

        let url = this.checkIsAAPrd() ? PRD_PROFILE : UAT_PROFILE;
        url +=  window.location.origin;
        console.log('onIncompleteProfile', url);
        window.location.href = url;
    }


    returnUserAgent(): string {
        // 'myhkg@android' || 
        const userAgent = navigator.userAgent;
        const isAndroid = userAgent.includes('myhkg@android');
        const isIos = userAgent.includes('myhkg@ios');
      
        if (isAndroid) {
          return 'myhkg@android';
        } else if (isIos) {
          return 'myhkg@ios';
        } else {
          return 'mobile';
        }
    }
      
    getAppLoginHint(): string {
        const userAgent = this.returnUserAgent();
        const appHints = {
          'myhkg@android': '@myhkg.com.aos',
          'myhkg@ios': '@myhkg.com.ios',
          'mobile': '@foodordering.com'
        };
      
        return appHints[userAgent] || appHints['default'];
      }
}
