import { AuthConfig } from 'angular-oauth2-oidc';
//
// export const authConfig: AuthConfig = {
//   issuer: 'https://demo.identityserver.io',
//   clientId: 'interactive.public', // The "Auth Code + PKCE" client
//   responseType: 'code',
//   redirectUri: window.location.origin + '/index.html',
//   silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
//   scope: 'openid profile email api offline_access', // Ask offline_access to support refresh token refreshes
//   useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
//   silentRefreshTimeout: 5000, // For faster testing
//   timeoutFactor: 0.25, // For faster testing
//   sessionChecksEnabled: true,
//   showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
//   clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
//   nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
// };
export const prdAuthConfig: AuthConfig = {
  issuer: 'https://login.hkairportrewards.com/foodordering',
  // clientId: '3MVG9iLRabl2Tf4g3YZuGozEB6BuN4jYluJMv9g2SVgMSck09SdmT8P6vb48QYASw_3FBxNTnJ6JejxZOSN1k',
  // UAT
  postLogoutRedirectUri: window.location.origin + '/court/5726866849660928',
  clientId: '3MVG9G9pzCUSkzZvhrw10zGS1O7V1PuKdjcbF2QCIqNBurlZge6BHMTUO2ilvwVQPaOtWK87WCgnXmLM8rjxg',
  dummyClientSecret : '2904FDF419F00882B6F05504B5CF6F027B8BD5CB0C3CF25DC67D0ABC464486C5',
  redirectUri: window.location.origin + '/court/5726866849660928',
  silentRefreshShowIFrame: false,
  scope: 'openid',
  useSilentRefresh: false,
  sessionChecksEnabled: true,
  strictDiscoveryDocumentValidation: false,
  // TODO:this url is not registered yet
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  skipIssuerCheck: true,
  showDebugInformation: true,
  silentRefreshTimeout: 30000

};

