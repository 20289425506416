import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BaseService {

    safeGet(obj, ruleString, defaultValue?) {
        const strArr = ruleString.split('.');
        let len = 0;

        while (len < strArr.length && obj[strArr[len]]) {
            obj = obj[strArr[len]]
            len++;
        }
        return obj || defaultValue;
    }

}
