import {ConfigService} from '../services/config.service';
import {Injectable, Injector, OnDestroy, OnInit, ViewChildren} from '@angular/core';
import {
    ActionSheetController,
    AlertController,
    IonRouterOutlet,
    LoadingController,
    ModalController,
    NavController,
    Platform,
    PopoverController,
    ToastController,
    PickerController
} from '@ionic/angular';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../services/navigation.service';
import {RouteTable} from '../route-table';
import {Location} from '@angular/common';

// import { ToastController } from 'ionic-angular/components/toast/toast';

@Injectable()
export class BasePage implements OnInit, OnDestroy {

    public static rootUrl = '';
    lastLoadingStatus = false;
    @ViewChildren(IonRouterOutlet) ionOutlet: IonRouterOutlet;
    public location: Location;
    public navigationService: NavigationService;
    public t: TranslateService;
    protected busy: boolean;
    protected lastTimeStamp: number;
    protected alertController: AlertController;
    // extended overlay controller
    protected loadingController: LoadingController;
    public modalController: ModalController;
    protected pickerController: PickerController;
    protected toastController: ToastController;
    protected actionSheetCtrl: ActionSheetController;
    protected popoverController: PopoverController;
    // protected rootNavController: NavController;
    protected navController: NavController;
    // protected navParams: NavParams;
    protected configService: ConfigService;
    // protected menuController: MenuController;
    // protected app: IonApp;
    protected platform: Platform;
    protected timer: any;
    protected active = false;
    protected router: Router;
    protected navParams: any;
    protected route: ActivatedRoute;
    // protected outlet: IonRouterOutlet;
    private loader: HTMLIonLoadingElement;
    private refresher: any;
    private loadingMap = {};

    // TODO: check usage
    /* getNav(app: IonApp) {
         var navs = app.getRootNav();
         if (navs && navs.length > 0) {
             return navs[0];
         }
         return app.getActiveNav('nav');
     }*/
    private cb: any;

    constructor(injector: Injector) {
        this.route = injector.get(ActivatedRoute);
        this.loadingController = injector.get(LoadingController);
        this.alertController = injector.get(AlertController);
        this.toastController = injector.get(ToastController);
        this.modalController = injector.get(ModalController);
        this.pickerController = injector.get(PickerController);
        this.actionSheetCtrl = injector.get(ActionSheetController);
        this.popoverController = injector.get(PopoverController);
        this.navController = injector.get(NavController);
        this.t = injector.get(TranslateService);
        // this.navParams = injector.get(NavParams);
        this.configService = injector.get(ConfigService);
        // this.outlet = injector.get(IonRouterOutlet);


        // this.menuController = injector.get(MenuController);
        // this.app = injector.get(IonApp);

        this.platform = injector.get(Platform);

        this.router = injector.get(Router);
        this.navParams = this.router.getCurrentNavigation();
        this.cb = this.getNavParams('callback');

        // let app = injector.get(App);
        // this.rootNavController = app.getRootNav();
        // this.rootNavController = this.getNav(this.app);

        // this.registerBack();
        this.location = injector.get(Location);
        this.navigationService = injector.get(NavigationService);
        this.checkAccessPage();
    }


    // method to handle pull to refresh, use like this: <ion-refresher (ionRefresh)="baseRefresh($event)">
    baseRefresh(refresher) {
        console.log('Begin async operation', refresher);
        this.refresher = refresher;
        this.refresh();
    }

    // child should overwrite this method if refresh
    refresh() {

    }

    // TODO: the loading may not be dismissed since the creation is async
    async loading(show, message: string = null) {
        try{
            console.log('loading, show', show);
            this.lastLoadingStatus = show;
            // console.trace();
            if (show) {

                if (this.refresher || this.loader) {
                    return;
                }

                
                // this.loader = this.loadingController.create({
                //     //content: 'Please wait...'
                // });
                this.loader = await this.makeLoader(message);
                if (this.lastLoadingStatus) {
                    this.loader.present();
                }
                console.log('loading showed');

            } else {

                if (this.loader) {
                    // this.loader.dismiss();
                    this.loader.dismiss().catch(() => {
                    });
                    this.loader = null;
                }

                if (this.refresher) {
                    this.refresher.complete();
                    this.refresher = null;
                }
                console.log('loading dismissed');


            }
        }catch(err){
            console.warn("check loading err",err)
        }
        

    }

    async loadingSelf(show, message: string = null, type?) {
        if (!type) {
            this.loading(show, message);
            return;
        }
        if (show) {
            this.loadingMap[type] = await this.makeLoader(message);
            this.loadingMap[type].present();
            setTimeout(() => {
                if (this.loadingMap[type]) {
                    this.loadingMap[type].dismiss();
                    delete this.loadingMap[type];
                }
            }, 6000);
        } else {
            if (this.loadingMap[type]) {
                this.loadingMap[type].dismiss();
            }
        }

    }
    async makeLoader(message: string = null): Promise<HTMLIonLoadingElement> {
        if (message == null || message.length === 0) {
            return await this.loadingController.create({});
        } else {
            return await this.loadingController.create({
                message: message
            });
        }
    }

    async showAlert(title?: string, message?: string,  buttons: any = [this.t.instant('buttons.ok')], dismissCallback?, otp = {}): Promise<HTMLIonAlertElement> {
        if (!buttons) buttons = ['OK'];
        const alert = await this.alertController.create({
            header: title,
            subHeader: message,
            buttons: buttons,
            ...otp
        });
        await alert.present();
        alert.onDidDismiss().then(v => {
            if (dismissCallback) dismissCallback();
        });
        return alert;
    }

    async showToast(message: string): Promise<HTMLIonToastElement> {
        const toast = await this.toastController.create({
            message: message,
            duration: 2000,
            position: 'top'
        });

        // toast.onDidDismiss(() => {
        //         //     console.log('Dismissed toast');
        //         // });

        toast.present();
        return toast;
    }

    async showError(err, dismissCallback?, errorMessage?): Promise<HTMLIonAlertElement> {
        console.log(err);
        this.loading(false);
        if (err.status === 0) {
            return this.showAlert(err.status, 'Network Error', null, dismissCallback);
        } else if (err.status === 401) {
            return this.showAlert(err.status, err['error'], null, dismissCallback);
        } else if (err.status === 415) { // 库存不足 back回首页
            return this.showAlert(err.status, errorMessage || err['error'],  null, dismissCallback);
        } else {
            return this.showAlert(err.status, err['error'], null, dismissCallback);
        }

        // return null;
    }

    async showPopoverController(component: any, componentProps: any, event: Event) {
        const popover = await this.popoverController.create({
            component,
            componentProps,
            event,
            translucent: true,
            backdropDismiss: true,
            animated: true,
            showBackdrop: true,
        });
        return await popover.present();
    }


    isCalledIn(milliseconds: number) {
        const currentTimeStamp = new Date().getTime();
        let result: boolean;
        if (!this.lastTimeStamp) {
            this.lastTimeStamp = currentTimeStamp;
            return false;
        }

        result = currentTimeStamp - this.lastTimeStamp < milliseconds;
        this.lastTimeStamp = currentTimeStamp;
        return result;
    }


    /*
    private startTimer() {
        if (!this.timer) {
            console.log('start timer');
            this.timer = setInterval(() => {

                this.onRefreshUI();

            }, this.getUIRefreshInterval());
        }
    }

    private stopTimer() {
        console.log('stop timer');
        clearInterval(this.timer);
        this.timer = null;
    }
    */

    ionViewWillEnter() {

        const interval = this.getUIRefreshInterval();
        if (interval > 0) {
            this.startTimer();
        }
        this.active = true;
    }

    ionViewWillLeave() {

        const interval = this.getUIRefreshInterval();
        if (interval > 0) {
            this.stopTimer();
        }
        this.active = false;
    }

    ngOnDestroy() {
        this.handleCallback(null);
    }

    handleCallback(result: any) {
        if (this.cb) {
            const cb = this.cb;
            this.cb = null;
            cb(result);
        }
    }

    // TODO: see if can maintain state when refersh
    /*

    registerBrowserState() {
        console.log('registered back state');
        // history.pushState({page: 'Generic'}, null, '');
    }*/

    /**
     *  workaround: relative route seems not work properly after the first navigation
     *  this function will check if the request is relative routing by checking the extras['relativeTo']
     *  if yes, it will convert the relative path to absolute path by prefixing window.location.pathname
     *
     * */
    // replace for navController.push
    push(page, params: any = {}, extras: NavigationExtras = {}): Promise<boolean> {
        const targetUrl = page.getPageUrl();
        return this.pushByUrl(targetUrl, params, extras);
    }

    pushByName(pageName, params: any = {}, extras: NavigationExtras = {}): Promise<boolean> {
        const targetUrl = RouteTable[pageName];
        if (!targetUrl) {
            console.error('cannot find url for page ', pageName);
        } else {
            console.log(`page ${pageName} at ${targetUrl}`);
        }
        return this.pushByUrl(targetUrl, params, extras);
    }

    pushByUrl(pageUrl, params?: any, extras?: NavigationExtras): Promise<boolean> {
        console.log('push by url', pageUrl);
        console.log('params', params);
        console.log('extras', extras);
        let temp = {};
        if (!this.navigationService.getRootPageUrl() || this.navigationService.getRootPageUrl() === '') {
            this.navigationService.configRootUrl();
        }
        if (extras) {
            extras['state'] = params;
            // will this be true if extras.relativeTo = this.route.root?
            if (extras.relativeTo === this.route) {
                pageUrl = window.location.pathname + '/' + pageUrl;
            } else if (!extras.relativeTo) {
                pageUrl = this.navigationService.getRootPageUrl() + '/' + pageUrl;
            }
            temp = extras;
        } else {
            pageUrl = this.navigationService.getRootPageUrl() + '/' + pageUrl;
            temp['state'] = params;
        }
        // temp['skipLocationChange'] = false;
        // temp['replaceUrl'] = false;
        this.navigationService.setNavigationParams(params);
        console.log('push to absolute url', pageUrl);
        this.navigationService.backByLocation = extras && (extras.skipLocationChange);
        if (this.navigationService.backByLocation) {
            window.history.pushState({}, this.t.instant('global.hkaaTitle'), window.location.href);
        }
        this.navigationService.lastParams = params;
        return this.navController.navigateForward([pageUrl], temp);
    }

    /*getRootUrl(): string {
        return this.navigationService.getRootPageUrl();
    }*/


    /*setRootUrl(newUrl, force: boolean = false) {
        if (!this.navigationService.getRootPageUrl() || force) {
            console.log(`rootUrl updated from ${this.navigationService.getRootPageUrl()} to ${newUrl}`);
            // BasePage.rootUrl = newUrl;
            // this.navigationService.setRootPage(newUrl);
        } else {
            console.log('try to update rootUrl', newUrl);
        }
    }*/

    // TODO: this removed the stack in html but cannot remove the history
    popToRoot(reload?) {
        if (reload) {
            location.replace(this.navigationService.getRootPageUrl());
            return;
        }
        return this.navController.navigateRoot([this.navigationService.getRootPageUrl()]);
    }

    // TODO: implement the function
    setRoot(page, params: any, attributes?: any) {
        return this.router.navigate([page.getPageUrl()], {state: params});
    }

    getNavParams(key: string) {
        const navigation = this.router.getCurrentNavigation();
        if (!navigation || !navigation.extras || !navigation.extras.state || !navigation.extras.state[key]) {
            // check route params;
            /**
             *  Please make sure your component is never reused, ie. change from one item to another without destroy the original one.
             *  Otherwise use the code below:
             *  this.route.paramMap.pipe(
             *  switchMap((params: ParamMap) =>
             *      params.get('id')
             *  );
             */

            if (this.route.snapshot.paramMap.has(key)) {
                return this.route.snapshot.paramMap.get(key);
            } else {
                return;
            }
        }
        return navigation.extras.state[key];
    }

    ngOnInit(): void {
    }

    protected getUIRefreshInterval(): number {
        return 0;
    }

    protected onRefreshUI() {
        console.log('refresh ui', new Date());
    }

    // child class should return this function if page can be an access point
    protected isAccessPage(): boolean {
        return false;
    }

    // if it's a temp access, it will not register current url as entry point
    protected isTempAccessPage(): boolean {
        return false;
    }


    private startTimer() {

        console.log('start timeout');
        this.timer = setTimeout(() => {
            this.onRefreshUI();
            clearTimeout(this.timer);
            this.startTimer();
        }, this.getUIRefreshInterval());

    }

    private stopTimer() {
        console.log('stop timeout');
        // clearInterval(this.timer);
        clearTimeout(this.timer);
        this.timer = null;
    }

    //
    private checkAccessPage() {

        let href;
        const hash = window.location.hash;

        if (!hash) {
            console.log('ignore root page!');
            return;
        }

        console.log('loc', window.location);
        let key = this.configService.config['appId'];
        if (key) {
            key = key + '.last-access';
        } else {
            key = 'last-access';
        }


        if (this.isAccessPage()) {

            if (!this.configService.entryPage) {
                this.configService.entryPage = this;
            }

            href = window.location.href;
            this.configService.setLocal(key, href);

            console.log('setting last access', href);
        }
        if (this.isTempAccessPage()) {

            if (!this.configService.entryPage) {
                this.configService.entryPage = this;
            }

        } else {


            if (this.configService.entryPage) {
                return;
            }


            href = this.configService.getLocal(key);
            if (!href) {
                this.configService.setLocal(key, null);
                return;
            }

            const current = window.location.href;

            if (current !== href) {
                // window.location.href = href;
                // window.location.reload();
            }
        }

    }
}

