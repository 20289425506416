import { Component,  EventEmitter, ViewChild, OnInit,  } from '@angular/core';

import {
    ActionSheetController,
    AlertController,
    IonRouterOutlet,
    MenuController,
    ModalController,
    NavController,
    Platform
} from '@ionic/angular';

import { Data } from 'aigens-ng-core';
import { SessionService } from './core/services/session.service';
import { BrowserService } from './core/services/browser.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { CodeManager } from './core/managers/code-manager';
import { NavigationService } from './core/services/navigation.service';
import { version } from '../../package.json';
import { ConfigService } from './core/services/config.service';
import { environment } from '../environments/environment.prod';
import { VersionCheckService } from './core/services/check-version.service';
import { JsonService } from './core/services/json.service';
declare var genkiJSInterface: any;
declare var gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

    // @ViewChild(Nav) nav: Nav;


    // rootPage: any; // = ByodBrandSplashPage;

    // pages: any[];
    redirect: string;
    // backByBrowser = false;
    // backByApp = false;
    // firstTimeLoading = true;
    public version: string = version;
    @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;

    private pageLoaded: EventEmitter<string> = new EventEmitter<string>();

    constructor(public configService: ConfigService, private navController: NavController, public jsonService: JsonService,
        private navigationService: NavigationService, private route: ActivatedRoute,
        private alertController: AlertController, private translate: TranslateService,
        private menu: MenuController, private sessionService: SessionService,
        public router: Router, public platform: Platform, public modalController: ModalController,
        public versionCheckService: VersionCheckService,
        public actionSheetCtrl: ActionSheetController, public browserService: BrowserService) {

        this.configService.app = this;
        console.warn('byod version', version);
        // if (window.location.href.match(/\/#/)) {
        //     window.location.replace(window.location.href.replace('/#', ''));
        //     return;
        // }
        this.pageLoaded.subscribe(data => {
            this.sendGAPage(data);
        });


    }

    ngOnInit(): void {
        this.configRootUrl();
        this.initializeApp();
        this.initTranslation();
        this.versionCheckService.initVersionCheck(environment.versionCheckURL);
        this.configService.setUserToken();

        console.log('register listener');
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.navigationService.navigating = true;
                //  Handle the old style hash url
                if (!!event.url && event.url.match(/^\/#/)) {
                    this.router.navigate([event.url.replace('/#', '')]);
                }
                if (!this.navigationService.getRootPageUrl()) {
                    console.log('current root url is: ', this.navigationService.getRootPageUrl());
                    this.configRootUrl();
                }
            }
            if (event instanceof NavigationError) {
                // this.navigationService.navigating = false;
                console.log('on navigation error, redirect to ', this.navigationService.getRootPageUrl());
                // this.router.navigate([this.navigationService.getRootPageUrl()]);
            }
            if (event instanceof NavigationEnd) {
                console.log('on navigation end');
                this.pageLoaded.emit(event.urlAfterRedirects);
                // gtag('set', 'page', event.urlAfterRedirects);
                // gtag('send', 'pageview');
                this.navigationService.navigating = false;
                this.navigationService.poping = false;
                this.modalController.getTop().then(modal => {
                    if (modal) {
                        modal.dismiss();
                    }
                });
                this.alertController.getTop().then(alert => {
                    if (alert && event.urlAfterRedirects !== '/court/5726866849660928') {
                        alert.dismiss();
                    }
                });
                this.actionSheetCtrl.getTop().then(actionSheet => {
                    if (actionSheet) {
                        actionSheet.dismiss();
                    }
                });
            }
            if (event instanceof NavigationCancel) {
                console.log('on navigation cancel');
                this.navigationService.navigating = false;
                this.navigationService.poping = false;
                this.modalController.getTop().then(modal => {
                    if (modal) {
                        modal.dismiss();
                    }
                });
                this.alertController.getTop().then(alert => {
                    if (alert) {
                        alert.dismiss();
                    }
                });
                this.actionSheetCtrl.getTop().then(actionSheet => {
                    if (actionSheet) {
                        actionSheet.dismiss();
                    }
                });
            }
        });
    }

    checkSupported(): boolean {

        /*
        var country = this.configService.getCountry();

        console.log(country);

        if (country == "cn" && !this.configService.isLocalhost()) {

            if (!this.browserService.isWeChat()) {
                this.redirectPage("wechat");
                return false;
            } else if (this.browserService.isIonicRoot()) {

                this.redirectPage("wechat");
                return false;

            }


        }*/

        return true;

    }

    initTranslation() {
        let l = this.configService.getLocale();
        let locale: string;
        locale = l ? l : this.configService.get('locale');
        console.log('default lang', locale);
        this.translate.setDefaultLang(locale);
        this.translate.currentLang = locale;
        this.configService.setLocale(locale);
        this.jsonService.use(locale);

    }

    // TODO: only used in CodePage

    // redirectPage(target: string) {
    //   this.redirect = target;
    //   this.
    //   this.nav.setRoot(RedirectPage, { target: target });
    // }

    initializeApp() {

        this.platform.ready().then(() => {

            const supported = this.checkSupported();

            console.log('browser supported', supported);

            if (supported) {

                this.processForward();
                this.setupBackButtonBehavior();
                // this.autoRegisterBrowserState();

            }
            // TODO: Legacy code for MPGS Hosted Checkout (redirect version)
            /*this.configService.getPreference('paymentInfo').then((info) => {
                console.log('paymentInfo', info);
                if (info) {
                    const orderId = info['orderId'];
                    const paymentId = info['paymentId'];
                    if (orderId && paymentId) {
                        const param = {};
                        param['defer'] = '';
                        param['data-complete'] = 'completeCallback';
                        param['data-error'] = 'errorCallback';
                        param['data-cancel'] = 'cancelCallback';
                        this.configService.loadScript('https://ap-gateway.mastercard.com/checkout/version/45/checkout.js', () => {
                        }, param);
                    }

                }

            });*/


            // Overwrite log to prevent large objects in log
            // if (this.configService.config && this.configService.config.default && this.configService.config.default.production) {
                console.log = () => {
                };
            // }

            const imgHost = this.configService.get('imageHost');
            Data.imageHost = imgHost;
            // this.menuController.swipeEnable(false, 'menu1');


        });
    }

    // TODO: use router guard to decode and guide?
    decodeForward(code: string) {
        const cm = new CodeManager(code, this.sessionService);
        // cm.go(this.nav);
    }

    processForward() {

        console.log(window.location);

        const hash = window.location.hash;
        const search = window.location.search;
        const href = window.location.href;
        const values = null;
        const params = {};
        // let name;

        // get wechat openId
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('openId')) {
            this.configService.openId = searchParams.get('openId');
        }

        if (search && search.indexOf('?code') === 0) {

            const code2 = search.substring(6, search.length);
            console.log('code page2!', code2);
            this.decodeForward(code2);
        } else if (!hash) {
            // this.nav.push(ByodBrandSplashPage);

        } else if (hash.indexOf('#/code/') === 0) {

            const code = hash.substring(7, hash.length);
            console.log('code page!', code);

            this.decodeForward(code);


        } else if (hash.indexOf('#/store/') === 0) {

        } else if (hash.indexOf('#/brand/') === 0) {

        } else if (hash.indexOf('#/login') === 0) {

        } else if (hash.indexOf('#/history/') === 0) {

            /*
            var values:any = hash.substring(2).split("/");


            var params = {};
            var name;
            for (let v of values) {
                if (!name) name = v;
                else {
                    params[name] = v;
                    name = null;
                }
            }

            this.nav.push(EmailOrderSummary, params);
            */

        }

    }

    openPage(page) {
        // TODO: go to the target route?

        // Reset the content nav to have just this page
        // we wouldn't want the back button to show in this scenario
        // this.nav(page.component, page.params);


    }

    // TODO: handle genki interface
    setupBackButtonBehavior() {

        /*   // If on web version (browser)
           if (window.location.protocol !== 'file:') {

             // Register browser back button action(s)

             window.onpopstate = (evt) => {

               console.log('onpop', evt);

               // var state = evt.state;
               // if (!state) return true;

               // Navigate back
               const modal = this.ionicApp._modalPortal.getActive();
               const needToShowAlert = (!this.nav.canGoBack() && this.nav.getActive(true).instance instanceof DirectCategoryItemListPage) ||
                   (this.nav.getActive(true).instance instanceof CategoryItemListPage && this.browserService.isBKApp());


               this.backByBrowser = true;
               if (modal && !this.backByApp) {
                 modal.dismiss();
               } else if (this.nav.canGoBack() && !needToShowAlert && !this.backByApp) {

                 this.nav.pop();
               } else if (needToShowAlert && !this.backByApp) {
                 const exitAlert = this.alertController.create({
                   title: 'The cart will be cleared.<br>Do you want to leave this page?',
                   buttons: [{
                     text: 'Cancel',
                     role: 'cancel',
                     handler: () => {
                       history.pushState({ page: 'DirectCategoryItemListPage' }, null, '');
                     }
                   }, {
                     text: 'OK',
                     handler: () => {
                       genkiJSInterface.exitwebview();
                     }
                   }]
                 });

                 exitAlert.onDidDismiss(() => {
                   if (genkiJSInterface.hasAlert) {
                     genkiJSInterface.hasAlert(false);
                   }
                 });

                 if (genkiJSInterface.hasAlert) {
                   genkiJSInterface.hasAlert(true);
                 }

                 exitAlert.present();
               }
               setTimeout(() => {
                 this.backByBrowser = false;
               }, 1000);


             };

           }*/

    }


    /* autoRegisterBrowserState() {

       // If on web version (browser)
       if (window.location.protocol !== "file:") {

         this.app.viewDidLoad.subscribe((component) => {


           if (component instanceof ViewController) {
             var instance = component.instance;
             if (instance instanceof BasePage) {
               console.log("is page and reg history");

               history.pushState({ page: component.name }, null, window.location.href);


             }
             // else if(this.firstTimeLoading){
             //     this.firstTimeLoading = false;
             // }
           }


         });

         this.app.viewWillUnload.subscribe((component) => {


           if (component instanceof ViewController) {
             var instance = component.instance;
             if (instance instanceof BasePage && !(instance instanceof OrderStatusPage)) {
               console.log("remove page and unreg history");
               if (!this.backByBrowser) {
                 this.backByApp = true;
                 console.log("in history back");
                 if (this.browserService.isBKApp()) {
                   history.back();

                 }
                 setTimeout(() => {
                   this.backByApp = false;
                 }, 500);
               }
             }
           }


         });


       }

     }*/

    private configRootUrl() {
        // TODO: config the root page
        /**
         *  check params to generate root page url
         *  /store/{id}/spot/{tableNo}
         *  /store/{id}/mode/{mode}
         *  /court-store-list/{id}/mode/{mode}
         *  /court-store-list//{id}/spot/{tableNo} ?
         *  please add more cases here
         * */
        const temp = window.location.pathname;
        let rootUrl = '';
        const storeIndex = temp.indexOf('store/');
        const courtIndex = temp.indexOf('court-store-list/');
        let modeEnd = 0;
        if (storeIndex !== -1) {
            rootUrl += 'store/';
            modeEnd = temp.indexOf('/', storeIndex + 6);
            rootUrl += temp.substring(storeIndex + 6, modeEnd);
        } else if (courtIndex !== -1) {
            rootUrl += 'court-store-list/';
            modeEnd = temp.indexOf('/', courtIndex + 17);
            rootUrl += temp.substring(courtIndex + 17, modeEnd);
        }
        const spotIndex = temp.indexOf('spot/');
        if (spotIndex !== -1) {
            rootUrl += '/spot/';
            modeEnd = temp.indexOf('/', spotIndex + 5);
            if (modeEnd !== -1) {
                rootUrl += temp.substring(spotIndex + 5, modeEnd);
            } else {
                rootUrl += temp.substring(spotIndex + 5);
            }
        }
        const courtModeIndex = temp.indexOf('court/'); // court  Mode
        if (courtModeIndex !== -1) {
            rootUrl += 'court/';
            modeEnd = temp.indexOf('/', courtModeIndex + 6);
            rootUrl += temp.substring(courtModeIndex + 6, modeEnd !== -1 ? modeEnd : temp.length + 1 ); // court/123231   后面没 /
            if (temp.indexOf('/healthcheck') > -1) {
                rootUrl += '/healthcheck';
            }
        }
        const keyIndex = temp.indexOf('key/');
        if (keyIndex !== -1) {
            rootUrl += '/key/';
            let keyEnd = temp.indexOf('/', keyIndex + 4);
            if (keyEnd !== -1) {
                rootUrl += temp.substring(keyIndex + 4, keyEnd);
            } else {
                rootUrl += temp.substring(keyIndex + 4);
            }
        }
        const modeIndex = temp.indexOf('mode/');
        if (modeIndex !== -1) {
            rootUrl += '/mode/';
            modeEnd = temp.indexOf('/', modeIndex + 5);
            if (modeEnd !== -1) {
                rootUrl += temp.substring(modeIndex + 5, modeEnd);
            } else {
                rootUrl += temp.substring(modeIndex + 5);
            }
        }
        this.navigationService.setRootPageUrl(rootUrl);
        console.log('set root Url', rootUrl, window.location.href);
        if (!(<any>document.getElementById('baseHref')).href || (<any>document.getElementById('baseHref')).href === '/') {
            (<any>document.getElementById('baseHref')).href = rootUrl;
        }
    }


    private sendGAPage(urlAfterRedirects) {
        let temp = urlAfterRedirects;
        console.log('event:', temp);
        console.log('root:', this.navigationService.getRootPageUrl());
        if (temp.includes(this.navigationService.getRootPageUrl())) {
            temp = temp.slice(this.navigationService.getRootPageUrl().length + 1);
        }
        console.log('result:', this.configService.GAsuffix + temp);
        gtag('config', 'UA-145294217-1', { 'page_path': this.configService.GAsuffix + temp });
    }
}
