import {BrowserService} from './browser.service';
import {Charge, Data, Store} from 'aigens-ng-core';
import {AQuery} from '../base/aquery';
import {BaseService} from '../base/base-service';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

declare var Stripe: any;
declare var window: any;
declare var ApplePaySession: any;

@Injectable({providedIn: 'root'})
export class PaymentService extends BaseService {

    aq: AQuery;
    stripe: any;
    stripeKey: string;
    hasActivePayment = false;
    supported: any = {};
    payments: any;
    constructor(private http: HttpClient, private configService: ConfigService, private browserService: BrowserService) {
        super();
        this.aq = new AQuery(http, configService);
    }


    preload(store: Store, callback: Function) {


        if (!store) {
            return;
        }
        if (!store.brand) {
            return;
        }
        // if (!store.brand['payments']) {
        //     return;
        // }
        // const payments = store.brand['payments'];
        const method = {};
        let mid: string;
        const loadedUrl = {};

        console.log('TCL: PaymentService -> preload -> this.payments', this.payments);
        this.payments.forEach(payment => {
            const gateway = payment['gateway'];
            const param = {};
            let url: string;
            let callbackHandler: any;


            if (payment['merchantId']) {
                mid = payment['merchantId'];
            }

            if (gateway === 'stripe') {

                console.log('loading stripe');
                url = 'https://js.stripe.com/v3/';
                callbackHandler = () => {
                    console.log('script loaded');
                    this.prepare(store, callback);
                };
            }

            // Legacy code for host checkout
            /*if (gateway === 'mpgs' && payment['method'] !== 'creditcard') {
                param['defer'] = '';
                param['data-complete'] = 'completeCallback';
                param['data-error'] = 'errorCallback';
                param['data-cancel'] = 'cancelCallback';
                url = 'https://ap-gateway.mastercard.com/checkout/version/45/checkout.js';
                callbackHandler = () => {
                    callback();
                };
                console.log('loading mpgs');

            }*/

            if (gateway === 'mpgs' && (payment['method'] === 'creditcard' || payment['method'] === 'session')) {
                param['defer'] = '';

                let apiHost = payment['APIHost'];
                let version = payment['version'];
                url = (apiHost ? apiHost : 'https://ap-gateway.mastercard.com') + '/form/version/' + (version ? version : '48') + '/merchant/' + mid + '/session.js';
                console.log('use mpgs host', apiHost ? apiHost : 'https://ap-gateway.mastercard.com');
                console.log('use mpgs version', version ? version : '48');
                callbackHandler = () => {
                    callback();
                };
                console.log('loading mpgs');

            }
            if (url && !loadedUrl[url]) {

                this.configService.loadScript(url, callbackHandler, param);
                loadedUrl[url] = true;
            }

        });

    }

    getStripe() {
        return this.stripe;
    }


    prepare(store: Store, callback: Function) {

        if (!store.pos) {
            return;
        }

        if (this.stripeKey) {
            this.checkPaymentMethodAvailable();
            return;
        }

        this.stripeKey = this.configService.get('stripe');

        console.log('stripe key', this.stripeKey);

        const stripe = Stripe(this.stripeKey);

        const pos = store.pos;

        console.log('payments?', pos);

        this.stripe = stripe;

        this.checkPaymentMethodAvailable();


    }

    checkPaymentMethodAvailable() {
        const stripe = this.stripe;
        if (!stripe) {
            this.hasActivePayment = true;
            return;
        }

        const paymentRequest = stripe.paymentRequest({
            country: 'HK',
            currency: 'hkd',
            total: {
                label: 'Aigens',
                amount: 400
            }
        });
        paymentRequest.canMakePayment().then((result) => {
            console.log('can make payment', result);

            if (result) {
                this.hasActivePayment = true;
            } else {
                this.hasActivePayment = false;
            }
        });
    }

    listenToken(payName: string, store: Store, grandTotal: number, callback: Function) {

        const amount = Math.floor(grandTotal * 100);
        const stripe = this.getStripe();

        const paymentRequest = stripe.paymentRequest({
            country: store.country.toUpperCase(),
            currency: store.currency.toLowerCase(),
            total: {
                label: payName,
                amount: amount
            }
        });

        this.listen(paymentRequest, callback);

        return paymentRequest;
    }

    payStripeCharge(token: string, charge: Charge): Observable<Charge> {

        const url = '/api/v1/pay/charge.json';

        const params = {};
        params['type'] = charge.type;
        params['token'] = token;
        params['amount'] = charge.amount;
        params['currency'] = charge.currency;
        params['groupId'] = '100';
        params['email'] = charge.email;
        params['subtype'] = charge.subtype;
        params['method'] = charge.method;

        if (charge.payeeId) {
            params['payeeId'] = charge.payeeId;
        }

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toData(Charge, jo['data'])));


    }

    canApplePay(): boolean {

        const aps = window.ApplePaySession;

        console.log('apple pay session', aps);

        if (aps) {
            if (aps.canMakePaymentsWithActiveCard) {
                return this.hasActivePayment;
            }
        }

        return false;
    }

    canAndroidPay(): boolean {

        if (this.browserService.ios) {
            return false;
        }

        if (window.PaymentRequest) {
            return this.hasActivePayment;
        }

        return false;
    }

    postApplePaySession(appleServerUrl: string, domain: string): Observable<any> {
        const url = this.configService.get('applePay'); // "https://pay-dot-aigensstoreapp.appspot.com/api/v1/pay/applesession.json";

        const body = {url: appleServerUrl, domain: domain};
        console.log(body);

        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;

        return aq.getJson().pipe(map(jo => jo));


    }

    private listen(paymentRequest, callback: Function) {

        const stripe = this.getStripe();

        paymentRequest.on('token', (ev) => {

            console.log('token!!', ev);

            callback(ev);


        });

        const elements = stripe.elements();
        const prButton = elements.create('paymentRequestButton', {
            paymentRequest: paymentRequest,
        });

        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then((result) => {

            console.log('can make payment?', result);

            if (result) {
                this.supported = result;
                console.log('can make payment', result);
                prButton.mount('#payment-request-button');
                console.log('prButton', prButton);
            } else {
                // document.getElementById('payment-request-button').style.display = 'none';
                console.log('cannot pay');
            }
        });
    }


}
