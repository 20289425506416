export const environment = {
    production: true,
    firebase: {
        apiKey: 'AIzaSyALcDXdd4tT5IAX4wA1ZVoeI3mJELf-Ypg',
        authDomain: 'aigensstoretest.firebaseapp.com',
        databaseURL: 'https://aigensstoretest.firebaseio.com',
        projectId: 'aigensstoretest',
        storageBucket: 'aigensstoretest.appspot.com',
        messagingSenderId: '70231813505'
    },
    versionCheckURL: 'version.json'

};
