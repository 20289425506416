import { BaseService } from '../base/base-service';

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Order } from 'aigens-ng-core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable()
export class CouponService extends BaseService {
    // 由于coupon是由AA那边获取， 数据格式要frontend自己写。包括check的logic 所以多了很多变量。。
    eCoupons = []; // server回来的数据
    eCouponsGroup = {}; // coupon分组的数据 {id => [offer]}
    eCouponsGroupQuantyMap = {}; // 已选数量 {id => qty}
    selectedCoupons = []; // 已选coupons

    cartCouponDispalyArray = []; // 做数据比cart显示
    cacheCoupons = []; // cache已选coupon, 方便edit orderItem后可以自动使用已选coupon
    constructor(public t: TranslateService, public alertController: AlertController, public toastController: ToastController) {
        super();
    }
    initEcoupon(data) {
        // init data
        let toTranslate = (coupon, value) => {
            switch (this.t.currentLang) {
                case 'en':
                    return (value === 'name' || value === 'description') ? coupon.extra.raw.offer[`${value}`] : coupon.extra.raw.offer[`${value}_en`];
                case 'zh-cn': // sc
                    return coupon.extra.raw.offer[`${value}_sc`];
                case 'zh': // tc
                    let b = coupon.extra.raw.offer[`${value}_tc`];
                    return coupon.extra.raw.offer[`${value}_tc`];
                default:
                    return coupon.extra.raw.offer[`${value}_tc`];
            }
        };
        let toObject = (e) => {
            let offerId = e.extra.raw.offer.id;
            return {
                offerId,
                name: toTranslate(e, 'name'),
                tc: toTranslate(e, 'tc'),
                expireStr: moment(e.expire).format('YYYY-MM-DD'),
                originCoupon: e,
                expire: e.expire,
                groupId: e.extra.raw.offer.id + '_' + e.expire,
                description: toTranslate(e, 'description'),
                faceValue: e.extra.raw.offer.faceValue
            };
        };

        let obj = {};

        data.map((e) => {
            let groupId = e.extra.raw.offer.id + '_' + e.expire;
            if (obj[groupId]) {
                obj[groupId].push(toObject(e));
            } else {
                obj[groupId] = [toObject(e)];
                if (this.selectedCoupons.length === 0) {
                    this.eCouponsGroupQuantyMap[groupId] = 0;
                }
            }
        });
        this.eCouponsGroup = obj;
        console.log('this.eCouponsGroup', obj);
    }

    addCoupon(groupId, grandTotal) { // 这里groupId， 就是coupon所属的groupId
        console.log('addCoupon')
        let {status, message} = this.canAdd(groupId, grandTotal);

        if (!status) {
            this.showMessage(message);
            return;
        }

        if (message === 'overuseCoupon') {
            this.showMessage(message);
        }

        if (this.eCouponsGroup[groupId].length > this.eCouponsGroupQuantyMap[groupId]) {
            this.eCouponsGroupQuantyMap[groupId]++;
        } else {
            console.log('quantity 超出最大值');
        }
        this.updateCouponData();
        this.cacheCoupons = this.selectedCoupons; // cache
    }
    showMessage(message) {
        switch (message) {
            case 'conditional':
                this.showAlert('', this.t.instant('pages.cart.conditional-error'));
                return;
            case 'overuseCoupon':
                this.showAlert('', this.t.instant('pages.cart.overuse-coupon'));
                return;
            case 'conditionNotMet':
                this.showAlert('', this.t.instant('pages.cart.coupon-not-met'));
                return;
            default:
                this.showAlert('', this.t.instant('pages.cart.coupon-not-met'));
                return;
        }
    }
    canAdd(groupId, grandTotal, onlyCheckSelected = false) {
        let res = {status: true, message: '' };
        
        let selectedCoupon = this.eCouponsGroup[groupId][0];

        // 条件1. 只能用一张conditionalOffer: true的优惠券；
        let isExisted = false;
        !onlyCheckSelected && this.selectedCoupons.map((c) => {
            if (c.extra.raw.offer.couponNature && c.extra.raw.offer.couponNature.conditionalOffer) { // 已选coupon已存在conditionalOffer coupon
                isExisted = true;
            }
        });

        if (selectedCoupon.originCoupon.extra.raw.offer.couponNature && selectedCoupon.originCoupon.extra.raw.offer.couponNature.conditionalOffer) { // 他是conditionalOffer coupon
            if (isExisted) {
                return {status: false, message: 'conditional' };
            }
        }

        // 条件2. 已用discount不能超过grandTotal
        let discount = this.getAllEcouponsDiscount();
        if (grandTotal <= discount) {
            return {status: false, message: 'overuseCoupon' };
        }

        // // just for display message
        // let selectedCouponFaceVaule = selectedCoupon.originCoupon.extra.raw.offer.faceValue;
        // if (grandTotal > discount &&  grandTotal < discount + selectedCouponFaceVaule ) {
        //     res.message = 'overuseCoupon';
        // }


        // 条件3. 消费金额不足
        let rules = selectedCoupon.originCoupon.extra.raw.offer.conditionRules;
        rules.map((r) => {
            if (r.operator === '>=') {
                if (grandTotal < r.value[0]) {
                    res = { status: false, message: 'conditionNotMet' };
                }
            }
        });
        return res;
    }
    deleteCoupon(groupId) {
        console.log('deleteCoupon');

        this.eCouponsGroupQuantyMap[groupId]--;
        this.updateCouponData();
        this.cacheCoupons = this.selectedCoupons;
    }

    setCartCouponDisplay() {
        // {
        //     id : {
        //         coupon: {};
        //         selectedQty: ;
        //         discounts: ;
        //     }
        // }
        let map = {};
        if (Object.keys(this.eCouponsGroup).length === 0 || Object.keys(this.eCouponsGroupQuantyMap).length === 0) {
            return [];
        }
        // tslint:disable-next-line:forin
        for (const key in this.eCouponsGroupQuantyMap) {
            let qty = this.eCouponsGroupQuantyMap[key];
            if (qty > 0 && this.eCouponsGroup[key]) {
                map[key] = {
                    coupon: this.eCouponsGroup[key][0],
                    groupId: key,
                    selectedQty: this.eCouponsGroupQuantyMap[key],
                    discounts: this.eCouponsGroup[key][0].originCoupon.extra.raw.offer.faceValue
                };
            }
        }
        this.cartCouponDispalyArray = Object.values(map) || [];
    }
    updateCouponData() {
        let selectedCoupons = [];
        // tslint:disable-next-line:forin
        for (const key in this.eCouponsGroupQuantyMap) {
            let qty = this.eCouponsGroupQuantyMap[key];
            if (qty > 0 && this.eCouponsGroup[key]) {
                let arr = [];
                this.eCouponsGroup[key].map((e) => {
                    arr.push(e.originCoupon);
                });
                selectedCoupons = selectedCoupons.concat(arr.slice(0, qty));
            }
        }
        console.log('selectedCoupons', selectedCoupons);
        this.selectedCoupons = selectedCoupons;
        this.setCartCouponDisplay();
    }
    checkCouponAvailable(grandTotal) {
        // when eidt order, should check coupon and update
        if (this.getAllEcouponsDiscount(this.cacheCoupons) === 0) return; // 无现金卷.

        let isConditionC = (c) => c.extra.raw.offer.couponNature && c.extra.raw.offer.couponNature.conditionalOffer;
        let avaGrandTotalAndDiscount = (coupons = []): boolean => {
            if (coupons.length === 0) return true;
            let discount = 0;
            coupons.map((c) => {
                discount += c.extra.raw.offer.faceValue;
            });

            let available = false;
            if (discount <= grandTotal) { // disocunt <= grandTotal
                console.log('coupon available');
                available = true;
            } else { // 减第一张 判断价格合适
                let v = coupons[0].extra.raw.offer.faceValue;
                available = grandTotal > discount - v;
            }
            return available;
        };
        let adjustCouponsF = (arr = [], conditionalGroupId?) => {
            let needToast = false;
            if (arr.length === 0 && conditionalGroupId) {
                this.eCouponsGroupQuantyMap[conditionalGroupId]--;
                this.updateCouponData();
                needToast = true;
            }
            for (let i = 0; i < arr.length; i++) {
                if (avaGrandTotalAndDiscount(arr.slice(i))) {
                    needToast = i !== 0;
                    for (const key in this.eCouponsGroupQuantyMap) {
                        this.eCouponsGroupQuantyMap[key] = 0;
                    }

                    arr.slice(i).map((cc) => {
                        let groupId = cc.extra.raw.offer.id + '_' + cc.expire;
                        this.eCouponsGroupQuantyMap[groupId]++;
                    });
                    this.updateCouponData();
                    break;
                }
            }
            // needToast && this.showToast(this.t.instant('pages.cart.adjust-coupon'), 'top');
        };

        let conditionC = this.cacheCoupons.find(isConditionC);
        // unConditionCoupons face value 由细到大
        let unConditionalC = this.cacheCoupons.filter((c) => !isConditionC(c)).sort((d1, d2) => d1.extra.raw.offer.faceValue < d2.extra.raw.offer.faceValue ? -1 : 1);


        // remove coupon logic !!!!!!!!!!!!!!!!
        // 优先remove unCondition coupon
        let arr_ = [...unConditionalC];
        if (conditionC) { // 有condition coupon, 优先check rules能不能用. 不能直接剔除
            let isCouponConditionOk = true;
            let rules = conditionC.extra.raw.offer.conditionRules;
            rules.map((r) => {
                if (r.operator === '>=') {
                    if (grandTotal < r.value[0]) {
                        isCouponConditionOk = false;
                    }
                }
            });
            let conditionalGroupId = conditionC.extra.raw.offer.id + '_' + conditionC.expire;
            isCouponConditionOk ? adjustCouponsF([...arr_, conditionC]) : adjustCouponsF([...arr_], conditionalGroupId);
        } else {
            adjustCouponsF([...arr_]);
        }
    }
    checkIsSelectedCp() {
        return !!(this.selectedCoupons.length > 0);
    }
    getAllEcouponsDiscount(c?): number {
        let coupons = c || this.selectedCoupons;
        let discount = 0;
        if (coupons.length > 0) {
            coupons.map((c) => {
                discount += c.extra.raw.offer.faceValue;
            });
        }
        return discount;
    }
    clear() {
        this.eCoupons = []; // server回来的数据
        this.eCouponsGroup = {}; // coupon分组的数据 {id => [offer]}
        this.eCouponsGroupQuantyMap = {}; // 已选数量 {id => qty}
        this.selectedCoupons = []; // 已选coupon
        this.cartCouponDispalyArray = []; // 做数据比cart显示
        this.cacheCoupons = [];
    }

    async showAlert(title?: string, message?: string, buttons: any = [this.t.instant('buttons.ok')], dismissCallback?): Promise<HTMLIonAlertElement> {
        const alert = await this.alertController.create({
            header: title,
            subHeader: message,
            buttons: buttons
        });
        await alert.present();
        alert.onDidDismiss().then(v => {
            if (dismissCallback) dismissCallback();
        });
        return alert;
    }

    
    async showToast(message: string, pos): Promise<HTMLIonToastElement> {
        const toast = await this.toastController.create({
            message: message,
            duration: 1500,
            position: pos
        });
        toast.present();
        return toast;
    }
}
