import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {environment} from '../environments/environment';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {AngularFireModule} from '@angular/fire';
import {UnlockerModule} from './shared/components/unlocker/unlocker.module';
import {ProgressBarModule} from './shared/components/progress-bar/progress-bar.module';
import {DialogsModule} from './dialogs/dialogs.module';
import {SessionService} from './core/services/session.service';
import {IonicStorageModule} from '@ionic/storage';
import {GoogleAnalyticsService} from './core/services/google-analytics.service';
import {LiveDataService} from './core/services/live-data.service';
import {MonitoringService} from './core/services/monitoring.service';
import {OrderService} from './core/services/order.service';
import {StoreService} from './core/services/store.service';
import {DiscountManager, InventoryManager, MenuManager, OrderManager, PriceManager, RecommendationManager} from 'aigens-ng-core';
import {MemberService} from './core/services/member.service';
import {MenuService} from './core/services/menu.service';
import {SdkConfigService} from './core/services/sdk-config-service';
import {SdkGooglePayService} from './core/services/sdk-google-pay.service';
import {BrowserService} from './core/services/browser.service';
import {ConfigService} from './core/services/config.service';
import {SdkBaseService} from './core/services/sdk-base-service';
import {PaymentService} from './core/services/payment.service';
import {FormsModule} from '@angular/forms';
import {TermsConditionsPage} from './dialogs/terms-conditions/terms-conditions';
import {TermsConditionsModule} from './dialogs/terms-conditions/terms-conditions.module';
import {SecureVerifyPage} from './dialogs/secure-verify/secure-verify-page';
import {SecureVerifyModule} from './dialogs/secure-verify/secure-verify.module';
import {MomentModule} from 'angular2-moment';
import {NavigationService} from './core/services/navigation.service';
import {MultOrderManager} from './core/managers/mult-order-manager';
import {SharedModule} from './shared/shared.module';
import {LocationStrategy} from '@angular/common';
import {MyLocationStrategy} from './core/my-location-strategy';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {MyPreloadStrategy} from './core/my-preload-strategy';
import {CartService} from './core/services/cart.service';
import {createTranslateLoader} from './shared/utilities/createTranslateLoader';
import {ThemeService} from './core/services/theme.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MemberApiService} from './core/services/member.api.service';
import {AiService} from './core/services/ai.service';
import {CouponService} from './core/services/coupon.service';
import {VersionCheckService} from './core/services/check-version.service';
import {SwUpdatesModule} from './core/sw-updates/sw-updates.module';
import {SystemConfigService} from './core/services/systemConfig.service';
import { JsonService } from './core/services/json.service';
import { TranslateJPipe } from './shared/pipes/translateJ.pipe';
import { SafeHtmlPipe } from './shared/pipes/safeHtmlPipe';
import { mdTransitionAnimation } from '@ionic/core/dist/collection/utils/transition/md.transition.js';
import {AuthModule} from "./core/auth/auth.module";
import { HKIADollarService } from './core/services/hkia-dollar.service';

@NgModule({
    declarations: [
        AppComponent],
    entryComponents: [AppComponent, TermsConditionsPage, SecureVerifyPage
    ],
    imports: [
        BrowserModule,
        FormsModule,
        IonicModule.forRoot({
            mode: 'ios',
            backButtonText: '',
            scrollAssist: false,
            scrollPadding: false,
            navAnimation: mdTransitionAnimation
        }),
        HttpClientModule,
        IonicStorageModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient, ConfigService, ThemeService]
            }
        }),
        SharedModule,
        AppRoutingModule,
        UnlockerModule,
        MomentModule,
        DialogsModule,
        TermsConditionsModule,
        SecureVerifyModule,
        ProgressBarModule,
        SwUpdatesModule,
        // NzMessageModule,
        // BrowserAnimationsModule,
        AuthModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
        ],
    providers: [
        CookieService,
        Keyboard,
        ConfigService,
        MemberService,
        MemberApiService,
        MenuService,
        // {provide: ErrorHandler, useClass: SentryErrorHandler},
        StoreService,
        OrderService,
        OrderManager,
        RecommendationManager,
        MenuManager,
        PriceManager,
        MultOrderManager,
        PaymentService,
        TranslateService,
        SdkBaseService,
        SdkConfigService,
        SdkGooglePayService,
        SessionService,
        BrowserService,
        LiveDataService,
        GoogleAnalyticsService,
        InventoryManager,
        MonitoringService,
        NavigationService,
        MyPreloadStrategy,
        CartService,
        CookieService,
        AiService,
        CouponService,
        VersionCheckService,
        SystemConfigService,
        DiscountManager,
        JsonService,
        TranslateJPipe,
        SafeHtmlPipe,
        // TODO: check if route strategy ok
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LocationStrategy, useClass: MyLocationStrategy},
        HKIADollarService,
    ],
    exports: [
        DialogsModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
