import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

var gtmScript = document.createElement("script");
var host = location.host;
if (host == "mobile.hongkongairportfood.com") {
    gtmScript.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\
  })(window,document,'script','dataLayer','GTM-Z222P4V');";
} else {
    gtmScript.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\
  })(window,document,'script','dataLayer','GTM-NHSS9Q7');";
}
document.head.appendChild(gtmScript);
// window.cookie2 = document.cookie;

platformBrowserDynamic().bootstrapModule(AppModule).then(res => {
    if ((<any>window).appBootstrap) {
        (<any>window).appBootstrap();
    }
    return res;
}).catch(err => console.log(err));
