import {GoogleAnalyticsService} from '../services/google-analytics.service';
import {SessionService} from '../services/session.service';
import {UUID} from 'angular2-uuid';
import {PaymentService} from '../services/payment.service';
import {MultOrderManager} from '../managers/mult-order-manager';
import {MenuService} from '../services/menu.service';
import {OrderService} from '../services/order.service';
import {BrowserService} from '../services/browser.service';
import {BasePage} from './base-page';
import {Injectable, Injector, OnInit} from '@angular/core';
import {LiveDataService} from '../services/live-data.service';
import {MonitoringService} from '../services/monitoring.service';
import {SdkConfigService} from '../services/sdk-config-service';
import {MemberService} from '../services/member.service';
import {TranslateService} from '@ngx-translate/core';
import {
    Brand,
    Category,
    Data,
    Image,
    InventoryManager,
    Item,
    ItemGroup,
    Member,
    MenuManager,
    Offer,
    OrderItem,
    OrderManager,
    Period,
    PriceManager,
    Setting,
    Store,
    RecommendationManager,
    Ad,
    Order
} from 'aigens-ng-core';
import {CartService} from '../services/cart.service';
import {
    getClosedTime,
    getPickupDelayTime,
    getTandCUrl,
    getTodayMillisecondsFromTimeString,
    getWeekdayStartAndEnd,
    hasHoliday,
    hasNumber,
    isAvailablePeriod,
    isEmailValid,
    isPeriodClosed,
    isUnitNumberFormat
} from '../../shared/utilities/checkFormatTool';
import {SystemConfigService} from '../services/systemConfig.service';
import {ActivatedRoute} from '@angular/router';
import {MemberApiService} from '../services/member.api.service';
import {ThemeService} from '../services/theme.service';
import {RouteTable} from '../route-table';
import {AiService} from '../services/ai.service';
import {CouponService} from '../services/coupon.service';
import {isPhoneNumberVaild} from '../../shared/utilities/checkFormatTool';
import {SdkApplepayService} from '../services/sdk-applepay-service';
import {JsonService} from '../services/json.service';
import {AuthService} from '../auth/auth.service';
import { PopupDialog } from 'src/app/dialogs/popup/popup';

declare var ga;
const ROOT_URL = '/home';

@Injectable()
export class MobileBasePage extends BasePage implements OnInit {

    static isAlerting = false;
    public t: TranslateService;
    public j: JsonService;
    public browserService: BrowserService;
    public couponService: CouponService;
    protected aiService: AiService;
    public orderService: OrderService;
    public orderManager: OrderManager;
    public paymentService: PaymentService;
    public memberService: MemberService;
    public memberApiService: MemberApiService;
    public menuService: MenuService;
    protected recommendationManager: RecommendationManager;
    public multOrderManager: MultOrderManager;
    public sessionService: SessionService;
    public monitoringService: MonitoringService;
    public gaService: GoogleAnalyticsService;
    public liveDataService: LiveDataService;
    public sdkConfigService: SdkConfigService;
    protected sdkApplepayService: SdkApplepayService;
    public themeService: ThemeService;
    public priceManager: PriceManager;
    public menuManager: MenuManager;
    public cartService: CartService;
    public authService: AuthService;
    public inApp = false;
    public inAppNav = false;
    public settings: Setting;
    protected inventoryManager: InventoryManager;
    protected defaultImage: string;
    protected defaultItemImage: string;
    protected phoneLength = {'852': 8, '853': 8, '65': 8, '60': 10, '886': 10, '86': 11};
    public systemConfigService: SystemConfigService;

    constructor(injector: Injector) {

        super(injector);
        this.t = injector.get(TranslateService);
        this.j = injector.get(JsonService);
        this.browserService = injector.get(BrowserService);
        this.orderService = injector.get(OrderService);
        this.paymentService = injector.get(PaymentService);
        this.menuService = injector.get(MenuService);
        this.memberService = injector.get(MemberService);
        this.aiService = injector.get(AiService);
        this.couponService = injector.get(CouponService);
        this.sessionService = injector.get(SessionService);
        this.monitoringService = injector.get(MonitoringService);
        this.gaService = injector.get(GoogleAnalyticsService);
        this.themeService = injector.get(ThemeService);

        this.sdkApplepayService = injector.get(SdkApplepayService);
        this.recommendationManager = injector.get(RecommendationManager);
        this.inventoryManager = injector.get(InventoryManager);
        this.multOrderManager = injector.get(MultOrderManager);
        this.liveDataService = injector.get(LiveDataService);
        this.sdkConfigService = injector.get(SdkConfigService);
        this.orderManager = injector.get(OrderManager);
        this.priceManager = injector.get(PriceManager);
        this.menuManager = injector.get(MenuManager);
        this.cartService = injector.get(CartService);
        this.authService = injector.get(AuthService);
        this.memberApiService = injector.get(MemberApiService);
        this.inApp = this.browserService.isInApp();
        this.systemConfigService = injector.get(SystemConfigService);

        this.inAppNav = this.browserService.isWeChat();
        if (Object.keys(this.orderService.settings).length > 0) {
            this.settings = this.orderService.getCurrentStoreSetting();
        } else {
            this.settings = new Setting();
        }
    }

    ngOnInit() {
        super.ngOnInit();
        try {
            this.googleAnalyticPageViewTrack();
        } catch (error) {
            console.log('googleAnalyticPageViewTrack', error);
        }
        // this.menuController.swipeEnable(false, 'menu1');

    }

  
    getInv(item: Item) {

        return this.inventoryManager.getInv(item);
    }

    isSoldout(item: Item, cat: Category = null): boolean {
        return this.inventoryManager.isSoldout(item) || !this.isAvailableItemWithValidModifierSettings(this.orderManager.store, cat, item.mgroups, this.orderManager.getOrder().takeout, Date.now(), true);

    }

    // menuManger.isAvailableGroup(store, cat, item.mgroups[0], orderManager.order.takeout, now, true)
    isAvailableItemWithValidModifierSettings(store: Store, cat: Category, groups: ItemGroup[], takeout: boolean, now: number, modifier: boolean) {
        let result = true;

        // mgroups
        if (groups && groups.length > 0) {
            for (const group of groups) {
                if (group.min > 0) {
                    if (!group.items || group.items.length < 1) {
                        result = false;
                    }

                    if (!result) {
                        break;
                    }
                }
                result = this.menuManager.isAvailableGroup(store, cat, group, takeout, now, modifier);

                if (!result) {
                    break;
                }
            }

        }

        return result;
    }

    isPublish(item: Item): boolean {

        return this.inventoryManager.isPublish(item);

    }

    monitoringStoreHandling(store: Store, handler: any) {
        this.monitoringService.enable = store.brand.features.indexOf('monitoring') !== -1;
        if (this.monitoringService.enable) {
            this.loading(true);
            this.monitoringService.listenStoreKioskStatus(store, () => {
                this.loading(false);
                this.showAlert('', this.t.instant('global.kiosk-offline-message'), [{
                    text: this.t.instant('buttons.ok'),
                    handler: () => {
                    }
                }]);
            }, () => {
                this.loading(false);
                handler();
            });
        } else {
            handler();
        }
    }

    readArray(object: any, key) {
        let tmpResult = key.split('[');
        let tmpKey = tmpResult[0];
        let index = String(tmpResult[1].split(']')[0]);
        if (!object[tmpKey] || object[tmpKey].length <= 0) return null;

        return object[tmpKey][index];
    }

    readObject(object: any, path: string): any {
        if (!(object instanceof Data)) return null;

        let keys = path.split('.').slice(1);
        let result = object;
        for (let key of keys) {
            let tmpSet = new Set(key);
            if (tmpSet.has('[') && tmpSet.has(']')) {
                result = this.readArray(result, key);
            } else {
                result = result[key];
            }

            if (!result) return null;
        }
        return result;
    }

    logoutCartHandle(cb?: any) {
        if ((this.multOrderManager.getOrders()).length > 0) {
            let alert = this.alertController.create({
                header: this.t.instant('global.logout-remind'),
                cssClass: ['cart-clear-alert', 'more-btn-margin'],
                buttons: [
                    {
                        text: this.t.instant('buttons.no'),
                        handler: () => {
                            // to do
                            // this.googleAnalyticEventTrack('back-button-event', 'cancel');

                        }
                    },
                    {
                        text: this.t.instant('buttons.yes'),
                        handler: () => {
                            this.multOrderManager.getOrderManagers().map((m) => {
                                m.clearOrderItems();
                            });
                            this.authService.logout();
                            this.authService.profileData = {};
                            cb && cb();
                        }
                    }
                ]
            });

            alert.then(data => data.present());
        } else {
            this.authService.logout();
            this.authService.profileData = {};
            cb && cb();
        }
    }
    loginSaveOrder(discountCode?) {
        console.log('loginSaveOrder');
        if ((this.multOrderManager.getOrders()).length > 0) {
            let now = new Date().getTime();
            let obj = {};
            let orders = this.multOrderManager.getOrders();
            // orders = Order.toOrdersData(orders);
            orders.map((order) => {
                order['diningTime']  = this.orderService.pickUpTime === '0' ?  now : Number(this.orderService.pickUpTime);
                order['diningTimeLabel'] = this.orderService.pickUpLabel;
                if (discountCode) {
                    order['discountCode'] = discountCode;
                }
            });
            obj[now] = orders;
            this.configService.setLocal('recentOrder', obj);
        }
    }
    isWechatAlipayInIos() {
        let isWechat = this.browserService.isWeChat();
        let isAlipay = this.browserService.isAlipay();
        let isAlipayHK = this.browserService.isAlipayHK();
        let isIos = this.platform.is('ios');
        return (isWechat || isAlipay || isAlipayHK) && isIos;
    }
    // HKaa crm
    authLogin(): Promise<any> {
        this.loginSaveOrder();
        return new Promise( async (resolve) => {
            // 这里的loading true, 在safari in app的时候不用触发.
            if (!this.isWechatAlipayInIos()) {
                this.loading(true);
            }
            try {
              await this.authService.login();
            } catch (error) {
                console.log('auth login error:', error);
                this.loading(false);
                return;
            }
            let profileObserver = await this.authService.getProfile();

            if (!profileObserver) {
                this.loading(false);
                return;
            }
            (profileObserver).subscribe((profileData) => {
                this.authService.profileData = profileData;
                this.loading(false);
                resolve && resolve(true);
                Object.keys(profileData).length > 0 && this.orderService.initLocalUsersData();
            }, err => {
                this.loading(false);
                if (err.status === 403) {
                    this.authService.onIncompleteProfile();
                } else {
                    this.showAlert(err.status, err['error'] && err['error'].message);
                }
            });
        });
    }

    crmPopover(ev, logoutCb = () => {}, page1, page2) {
        let modal;
        let openModal = async (page, params?, cssClass = '', event?) => {
            modal = await this.modalController.create({
                component: page,
                componentProps: { ...params },
                showBackdrop: true,
                backdropDismiss: true,
                cssClass
            });
            modal.present();
        };

        openModal(page1, {
            logout: () => {
                this.logoutCartHandle(logoutCb);
                modal.dismiss();
                this.popoverController.dismiss();
            },
            profile: () => {
                openModal(page2);
                modal.dismiss();
                this.popoverController.dismiss();
            },
        }, 'modal-languages');
    }

    async showModal(page, params?, cssClass = '', event?) {
        // profileModal
        const modal = await this.modalController.create({
            component: page,
            componentProps: {...params},
            showBackdrop: true,
            backdropDismiss: true,
            cssClass
        });
        modal.onDidDismiss().then((data) => {
        });
        modal.present();
    }

    checkStoreMaxItem(storeId: number): boolean {
        const orderManager: OrderManager = this.multOrderManager.enableMutlOrder ? this.multOrderManager.getOrderManager(storeId) : this.orderManager;
        const maxItems = orderManager.store.menu.maxItems;
        const infinity = maxItems === 0;
        return (maxItems <= orderManager.count && !infinity);
    }

    async showStoreMaxItemAlert() {

        const subtitle = this.t.instant('global.max-item-message');
        const confirm = this.t.instant('buttons.ok');

        const alert = await this.alertController.create({
            subHeader: subtitle,
            buttons: [confirm]
        });
        alert.present();


    }

    pushAndRemovePage(pushPage: any, param: {}, remainPage: any, callBack: Function) {
        // TODO: replace current history with remain page
        window.history.replaceState({}, this.t.instant('global.hkaaTitle'), RouteTable[remainPage] ? RouteTable[remainPage] : this.navigationService.getRootPageUrl());
        this.pushByName(pushPage, param).then(
            () => {
                // // find index of home page, and remove from home page
                // let index: number = this.navController.getViews().findIndex(
                //     view => (view.instance instanceof remainPage)
                // );
                // let current: number = this.navController.getActive().index;
                // console.log("views:" + this.navController.getViews()[0]);
                // console.log("index:" + index);
                // console.log("current:" + current);
                // this.navController.remove(index + 1, (current - 1) - index).then(() => {

                if (callBack) {
                    callBack();
                }
                // })


            }
        );
    }

    // 用 23:59:59 的時間與當前時間比較
    checkAdTodayCanShow(key: string): boolean {
        if (!key) return;
        let nowTime = new Date().getTime();
        let value = this.configService.getCookie(key) || '0';
        return nowTime > Number(value);
    }

    getEarliestClosedTime(stores: Store[]): string {
        let closeTime: string;

        stores.forEach((store) => {
            // checking opening[weekdays] may  = null


            const storeCloseTime = getClosedTime(store);
            const hours = storeCloseTime.split(':')[0];
            const minutes = storeCloseTime.split(':')[1];
            if (!closeTime) {
                closeTime = storeCloseTime;
            } else if (closeTime.split(':')[0] > hours) {
                closeTime = storeCloseTime;

            } else if (closeTime.split(':')[0] === hours) {
                if (closeTime.split(':')[1] >= minutes) {
                    closeTime = storeCloseTime;
                }
            }


        });

        return closeTime;
    }

    getEarliestCategoryClosedTime(orderItems: OrderItem[]): string {
        let closeTime: string;

        orderItems.forEach((orderItem) => {

            const catCloseTime = this.getCategoryClosedTime(orderItem);
            if (catCloseTime && catCloseTime.split(':').length >= 2) {

                const hours = catCloseTime.split(':')[0];
                const minutes = catCloseTime.split(':')[1];
                if (!closeTime) {
                    closeTime = catCloseTime;
                } else if (closeTime.split(':')[0] > hours) {
                    closeTime = catCloseTime;

                } else if (closeTime.split(':')[0] === hours) {
                    if (closeTime.split(':')[1] >= minutes) {
                        closeTime = catCloseTime;
                    }
                }
            }


        });

        return closeTime;
    }

    getCategoryClosedTime(orderItem: OrderItem): string {
        let closeTime: string;
        const weekdaysMap = {};
        const openings = orderItem.category.openings;
        if (!openings || !openings.weekdays) {
            return null;
        }
        openings.weekdays.forEach((weekday) => {
            weekdaysMap[weekday.weekday] = weekday;
        });
        closeTime = weekdaysMap[new Date().getDay()].endTime;
        return closeTime;

    }

    getEarliestPeriodClosedTime(stores: Store[]): string {
        let closeTime: string;

        stores.forEach((store) => {

            const storeCloseTime = this.getPeriodClosedTime(store);
            const hours = storeCloseTime.split(':')[0];
            const minutes = storeCloseTime.split(':')[1];
            if (!closeTime) {
                closeTime = storeCloseTime;
            } else if (closeTime.split(':')[0] > hours) {
                closeTime = storeCloseTime;

            } else if (closeTime.split(':')[0] === hours) {
                if (closeTime.split(':')[1] >= minutes) {
                    closeTime = storeCloseTime;
                }
            }


        });

        return closeTime;
    }

    getPeriodClosedTime(store: Store): string {
        let storeCurrentPeriodClosedTime: string;
        const [stopped, period] = this.stoppedPreOrder(store);
        if (!period) {
            storeCurrentPeriodClosedTime = getClosedTime(store);

        } else {
            const holidaySlot = hasHoliday(store.brand, period.openings);

            if (holidaySlot) {
                storeCurrentPeriodClosedTime = holidaySlot.endTime;
            } else if (period.openings && period.openings.weekdays) {
                const [dateStart, startString, dateEnd, endString] = getWeekdayStartAndEnd(period);
                storeCurrentPeriodClosedTime = endString;
            } else {
                storeCurrentPeriodClosedTime = period.endTime;

            }

        }
        return storeCurrentPeriodClosedTime;

    }

    getMaxPickupDelaytime(stores: Store[]): number {
        let maxTime = 0;
        stores.forEach((store) => {
            if (getPickupDelayTime(store) > maxTime) {
                maxTime = getPickupDelayTime(store);
            }
        });
        return maxTime;
    }

    isValidPickupTime(): boolean {
        const currentTime = new Date().getTime();
        const timelabel = this.orderService.pickUpLabel ? this.orderService.pickUpLabel : this.t.instant('global.eat-now');
        const pickedTimeNum = getTodayMillisecondsFromTimeString(timelabel);
        if (pickedTimeNum > currentTime || timelabel === this.t.instant('global.eat-now')) {
            return true;
        } else {
            this.showAlert('', this.t.instant('global.pick-up-time-invalid'));
            return false;
        }
    }

    genSetSession(key: string, callBack: any = null) {

        console.log('generating session id');

        this.configService.getPreference(key + '.session').then((session) => {

            if (!session) {
                const uuid = UUID.UUID().toString();
                console.log('generated session', uuid);
                session = uuid;
                this.configService.putPreference(key + '.session', uuid);
            } else {

                console.log('existing session', session);
            }

            this.orderService.session = session;

            if (callBack) {
                callBack();
            }

        });

    }

    clickReward(offer: Offer, store: Store, page: any) {

        if (offer.reward.discount && offer.reward.discount.benefits && offer.reward.discount.benefits[0]) {
            const category = store.menu['cmap'][offer.reward.discount.benefits[0].code];
            const layout = category.layout && category.layout !== 'none' ? category.layout : store.menu.settings.itemLayout;
            const cb = (oi) => {
                const tmpoi = Data.toData(OrderItem, JSON.parse(JSON.stringify(oi)));

                offer.orderitems.push(tmpoi);
                this.offerHandling(offer);
            };
            // TODO: check logic ? relative route?

            this.pushByName(page, {store: store, category: category, layout: layout, handler: cb});


        } else {
            this.offerHandling(offer);
        }


    }

    offerHandling(offer: Offer) {
        this.orderService.selectedReward = offer;
        this.orderManager.order.offers.push(this.orderService.selectedReward);
        this.orderManager.calculate();
        this.showToast(this.t.instant('global.add-reward-msg'));
    }

    checkingStorePeriodClosed(): number[] {
        const [periods, storeIds] = this.multOrderManager.getStoresCurrentOrderingPeriod();
        const closeStoreIds: number[] = [];
        const now: Date = new Date();

        periods.forEach((period, index) => {
            const store = this.multOrderManager.getStore(storeIds[index]);
            if (!period) {
                const closeTime = getTodayMillisecondsFromTimeString(getClosedTime(store));
                const currentTime = new Date().getTime();
                if (currentTime >= closeTime) {
                    closeStoreIds.push(storeIds[index]);
                }

            } else if (!isAvailablePeriod(store, period, now)) {
                closeStoreIds.push(storeIds[index]);
            }


        });

        return closeStoreIds;
    }

    saveWeekDay(period: Period, isMulti: boolean = false) {
        if (period.openings) {
            const todayWeeday = new Date().getDay();
            const tmpWeekday = todayWeeday - 1 < 0 ? 6 : todayWeeday - 1;
            const tmpStartHour = new Date().setHours(Number(period.openings.weekdays[tmpWeekday]['startTime'].split(':')[0]),
                Number(period.openings.weekdays[tmpWeekday]['startTime'].split(':')[1]), 0, 0);
            const tmpEndHour = new Date().setHours(Number(period.openings.weekdays[tmpWeekday]['endTime'].split(':')[0]), Number(period.openings.weekdays[tmpWeekday]['endTime'].split(':')[1]), 0, 0);
            if (tmpEndHour < tmpStartHour) {
                if (new Date().getTime() < tmpEndHour) {
                    period['savedWeekday'] = tmpWeekday;

                } else {
                    period['savedWeekday'] = todayWeeday;
                }
            } else {
                period['savedWeekday'] = todayWeeday;

            }

        }
        if (!isMulti) {
            this.orderService.currentPeriod = period;

        }
    }

    async adsPopUp(ads, params, popupKey, popupLocation: string, dismissCallback?: Function) {
        let canShow = this.checkAdTodayCanShow(popupKey);
        let isMember = this.authService.checkHasAAMember();
        let adsToShow = ads.filter(ad => {
            if (ad.type !== 'popup' && ad.type !== 'slider')
                return false;
            if (!ad.images['default'] && (!ad.desc || !ad.desc.startsWith('http')))
                return false;
            if (ad.users && ad.users.length > 0) {
                if (ad.users.indexOf('guest') === -1 && !isMember)
                    return false;
                if (ad.users.indexOf('member') === -1 && isMember)
                    return false;
            }
            if ((ad['stage'] !== popupLocation) && (ad.stages && ad.stages.length > 0 && ad.stages.indexOf(popupLocation) === -1))
                return false;
            if (ad['channels'] && ad['channels'].length > 0 && ad['channels'].indexOf('BYOD') < 0) {
                return false;
            }
            return canShow || ad['repeats'];
        });

        if (adsToShow.length > 0) {


            const input = { ...params, ads: adsToShow };
            let modal = await this.modalController.create(
                {
                    component: PopupDialog,
                    componentProps: input,
                    showBackdrop: true,
                    backdropDismiss: true,
                    cssClass: 'no-bgColor'
                });

            modal.onDidDismiss().then((data) => dismissCallback(data));
            modal.present().then(() => {
                // save popup end time
                this.saveTodayEndTime(popupKey);
            });
        }
    }

    
    stoppedPreOrder(store: Store): [boolean, Period] {
        let periods: Period[] = store.menu ? store.menu.periods : [];
        let currentPeriod: Period;
        const now: Date = new Date();
        const currentTime = now.getTime();
        let stopped: boolean = null;
        if (!periods) {
            periods = [];
        }

        if (Store.isWholeDayOpen(store)) {
            const period = new Period();
            period.endTime = store.menu.periods[store.menu.periods.length - 1].endTime;
            period.end = store.menu.periods[store.menu.periods.length - 1].end;
            period.startTime = store.menu.periods[0].startTime;
            period.start = store.menu.periods[0].start;
            period['prestop'] = store.menu.periods[store.menu.periods.length - 1]['prestop'] ? store.menu.periods[store.menu.periods.length - 1]['prestop'] : 0;

            stopped = isPeriodClosed(store, period, currentTime);
            return [stopped, period];
        }

        periods.forEach((period, index) => {

            if (isAvailablePeriod(store, period, now)) {
                if (stopped === null) {
                    this.saveWeekDay(period, true);
                    stopped = isPeriodClosed(store, period, currentTime);
                    currentPeriod = period;
                }
            }


        });

        if (periods.length <= 0) {
            const closeTime = getTodayMillisecondsFromTimeString(getClosedTime(store));
            const current = new Date().getMilliseconds();
            stopped = (current >= closeTime);
            return [stopped, null];
        }

        if (stopped === null) {
            return [true, null];
        }
        return [stopped, currentPeriod];

    }


    checkingStoresStopOrdering(stores: Store[]): number[] {
        const closeStoreId: number[] = [];
        stores.forEach((store) => {
            const [stopped, period] = this.stoppedPreOrder(store);
            if (stopped) {
                closeStoreId.push(store.id);
            }
        });
        return closeStoreId;
    }

    haveStoresStopOrdering(needToPop: boolean = false, popTarget?: any): boolean {
        let closeStoreId = this.checkingStorePeriodClosed();
        closeStoreId = closeStoreId.concat(this.checkingStoresStopOrdering(this.multOrderManager.getStores()).filter((storeId) => {
                return closeStoreId.indexOf(storeId) === -1;
            })
        );
        const storeName: string[] = [];
        closeStoreId.forEach((id) => {
            storeName.push(this.multOrderManager.getStore(id).name);
        });

        if (closeStoreId.length > 0) {
            const message = storeName.join(',') + this.t.instant('global.store-closed-message');
            this.showAlert('', message, [{
                text: this.t.instant('ok'),
                handler: data => {

                    closeStoreId.forEach((id) => {
                        this.multOrderManager.deleteOrderManager(id);
                        this.multOrderManager.deleteStoreCurrentOrderingPeriod(id);
                    });

                    if (needToPop && popTarget) {
                        // find index of home page, and remove from home page
                        // TODO:check logic
                        // this.navController.back();
                        this.popToRoot();

                        // let index: number = this.navController.getViews().findIndex(
                        //     view => (view.instance instanceof popTarget)
                        // );
                        // let current: number = this.navController.getActive().index;
                        // this.navController.remove(index + 1, (current - 1) - index).then(() => {
                        //     this.navController.pop();
                        // });
                    }
                }
            }]);
            return true;
        }

        return false;
    }

    googleAnalyticPageViewTrack(oninit: boolean = false) {
        if (this.pageName() !== '') {
            this.gaService.pageViewTrack(this.pageName(), oninit);
        }
    }

    googleAnalyticEventTrack(label: string, action?: string) {
        // this.gaService.eventTrack(this.pageName(),event,label);
        // ga('send', 'event', page, event, label);
        if (action) {
            this.buttonPerformanceTracker(action, label);
        } else {
            this.pagePerformanceTracker(label);
        }

    }

    pagePerformanceTracker(label: string) {
        if (!this.gaService.targetId) {
            let found = false;

            if (this.orderService.isBK) {
                this.gaService.targetId = '800001';
            } else {
                for (const value of window.location.pathname.split('/')) {// v2 ulrPamrams is not in hash
                    if (found) {
                        this.gaService.targetId = value;
                        break;
                    }

                    if (value === 'store' || value === 'brand' || value === 'storeId') {
                        found = true;
                    }

                }

            }
        }
        console.log('targetId:', this.gaService.targetId);
        if (this.gaService.targetId) {
            this.gaService.eventTrack(this.gaService.targetId, this.pageName(), label);

        }
    }

    buttonPerformanceTracker(action: string, label: string) {
        this.gaService.eventTrack('button performance', action, label);

    }

    checkingDeliveryInformation(showAlert: boolean = true): boolean {
        let message = 'Please enter ';

        if (this.orderService.isBK) {
            if (!this.orderService.address.block || this.orderService.address.block === '') {
                message = message + 'Block / House';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }

            if (!this.orderService.address.street || this.orderService.address.street === '') {
                message = message + 'Street';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }


            if (!this.orderService.address.number || this.orderService.address.number === '' || isUnitNumberFormat(this.orderService.address.number)) {
                message = message + 'valid Unit Number';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }

            if (!this.orderService.firstName || this.orderService.firstName === '' || hasNumber(this.orderService.firstName)) {
                message = message + 'First Name';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }

            if (!this.orderService.lastName || this.orderService.lastName === '' || hasNumber(this.orderService.lastName)) {
                message = message + 'Last Name';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }
            if (!isEmailValid(this.orderService.email)) {
                message = message + 'vaild Email';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }

            if (!this.orderService.phone || this.orderService.phone === '') {
                message = message + 'Phone';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }

        }


        return true;
    }

    async showToast(message: string, duration: number = 2000, position: 'top' | 'bottom' | 'middle' = 'bottom', cssClass: string = 'toast-box'): Promise<HTMLIonToastElement> {
        let buttons = [];
        console.log('toast', this.t.instant('global.item-added'));
        if (message === this.t.instant('global.item-added')) {

            buttons = [
                {
                    side: 'start',
                    icon: 'item-added-tick'
                }
            ];
        }

        const toast = await this.toastController.create({
            message: message,
            duration: duration,
            position: position,
            cssClass: cssClass,
            buttons,
        });

        toast.onDidDismiss().then(() => {
            console.log('Dismissed toast');
        });

        toast.present();
        return toast;
    }

    async openTermsModal(brand: Brand, handler: any, page: any, needToAgreeMarketing: boolean = false, isFb: boolean = false, handleFail: boolean = false, orderPlaceMarketing: boolean = false, useByodTnc: boolean = true) {
        const url = getTandCUrl(brand, useByodTnc ? 'byodTc' : 'regTc');
        if (url) {
            const params = {url: url, needToAgreeMarketing: needToAgreeMarketing};
            if (isFb) {
                params['fbHandler'] = handler;
            }
            params['brandName'] = brand.name;
            const modal = await this.modalController.create({
                component: page,
                componentProps: params,
                backdropDismiss: false
            });
            modal.onDidDismiss().then((result) => {
                console.log('tnc result', result.data);
                if (result && result.data && !result.data.fail && handler && !isFb) {
                    handler(result.data);
                }
            });

            modal.present();

        } else {
            console.warn('Please add Terms and Conditions');
            this.loading(false);
            this.showAlert(this.t.instant('pages.base.missing-tnc'), '');
        }

    }
    adPeriodCheck(ad: Ad): Boolean {
        if (!ad) return;
        let start = ad['start'];
        let end = ad['end'];
        let now = new Date().getTime();
        if (start && end) { // 可以alone
            return start <= now && end >= now;
        } else if (start) {
            return start <= now;
        } else if (end) {
            return end >= now;
        } else {
            return true;
        }
    }
    getPickupTime(): number {
        let pickupTime = Number(this.orderService.pickUpTime);

        if (isNaN(pickupTime) || pickupTime === 0) {
            pickupTime = new Date().getTime();
        } else if (pickupTime.toString().length < 11) {
            /// 轉成毫秒
            pickupTime = pickupTime * 1000;
        }
        return pickupTime;
    }

    settingInventory(store: Store) {

        const type = store.menu.settings.inventory;

        if (!type || type === 'default') {
            const isChina = this.configService.getCountry() === 'cn';
            this.getInventory(!isChina, store.id);

        } else if (type === 'server' || type === 'firebase') {
            const isFirebase = type === 'firebase';
            this.getInventory(isFirebase, store.id);
        } else if (type !== 'none') {
            console.log('new type');
            // handling....
        }
    }

    getInventory(isFirebase: boolean, storeId: number) {
        if (!isFirebase) {
            this.menuService.getInventory(storeId).subscribe((inventory) => {
                console.log('inventory', inventory);
                this.inventoryManager.inventory = inventory;
            }, (err) => {
                this.showError(err);
            });
        } else {
            this.liveDataService.unlistenAll();
            this.liveDataService.listenInventory(storeId);

        }
    }

    setTheme(brand: Brand, store?: Store) {
        if (brand && brand.themes && brand.themes['byod'] && brand.themes['byod']['fav'] && brand.themes['byod']['fav'][0] && brand.themes['byod']['fav'][0]['url']) {
            this.themeService.setFavicon(brand.themes['byod']['fav'][0]['url']);
        }
        if (brand && brand['closed']) {
            setTimeout(() => {
                let d = document.getElementById('close-img');
                if (d) {
                    let url;
                    if (brand && brand.themes && brand.themes['byod'] && brand.themes['byod']['logo'] && brand.themes['byod']['logo'][0] && brand.themes['byod']['logo'][0]['source']) {
                        url = 'url(\'' + brand.themes['byod']['logo'][0]['source'] + '\')';
                    } else {
                        url = 'url(\'' + '/assets/images/hkaaclose.png' + '\')';
                    }
                    d.style.backgroundImage = url;

                }
            }, 1000);
        }

        let sheets = document.styleSheets;

        let sheet = sheets[sheets.length - 1];

        let insertFn = sheet['insertRule'];

        if (!insertFn) {
            return;
        }

        if (screen.width > 500) {
            const ionPage = document.getElementsByTagName('ion-app')[0];
            ionPage.id = 'ion-page-best-fit';
        }
        if (!this.orderService.isCourt && store && store.brandId) {
            this.setID(String(store.brandId));

        }


        let bg = store && this.getColor(store, 'bg');
        if (!bg && brand) bg = this.getColor(brand, 'bg');

        let textcolor = store && this.getColor(store, 'text');
        if (!textcolor && brand) textcolor = this.getColor(brand, 'text');
        let img;
        if (brand) {
            img = this.getSplashBackground(brand, (screen.width * 16) / 9);
        }


        if (bg) {

            // this.settingCssClass('header',"toolbar-background","background-color",bg);
            // this.settingCssClass('footer',"toolbar-background","background-color",bg);
            // this.addCss(sheet, ".theme1 .fab", "background-color", bg);
            // this.addCss(sheet, ".theme1 .toolbar-background", "background-color", bg);
            // this.addCss(sheet, ".theme1","background-color",bg);
            this.addCssInHead('toolbarCss', '.theme1 .toolbar-background', 'background-color', bg);
            this.addCssInHead('backgroundCss', '.theme1', 'background-color', bg);

        }

        if (textcolor) {
            this.addCssInHead('splashTextColorCss', '.themeText ion-icon, .themeText ion-label, .themeText p', 'color', textcolor);
        } else {
            this.addCssInHead('splashTextColorCss', '.themeText ion-icon, .themeText ion-label, .themeText p', 'color', '#000');
        }

        if (img) {
            let urlString = 'url(\'' + img + '\')';
            this.addCssInHead('backgroundImageCss', '.theme1.content', 'background-image', urlString);
            this.addCssInHead('backgroundPositionCss', '.theme1.content', 'background-position', 'center');
            this.addCssInHead('backgroundSizeCss', '.theme1.content', 'background-size', 'cover');
        } else if (!img && !bg) {
            this.addCssInHead('backgroundImageCss', '.theme1.content', 'background-image', 'url(\'../assets/images/defaultSplash.jpg\')');
            this.addCssInHead('backgroundPositionCss', '.theme1.content', 'background-position', 'center');
            this.addCssInHead('backgroundSizeCss', '.theme1.content', 'background-size', 'cover');
        }
    }

    setID(brandID: string) {

        const body = document.getElementsByTagName('BODY')[0];
        if (brandID) {
            body.id = 'cId' + brandID;
        } else {
            body.removeAttribute('id');
        }
    }

    isProfileCompleted(user: Member): boolean {
        if (!user.firstName || user.firstName === '') {
            return false;
        }
        if (!user.lastName || user.lastName === '') {
            return false;
        }
        if (!user.gender || user.gender === '') {
            return false;
        }
        if (!user.birthdate) {
            return false;
        }

        return true;
    }

    checkingMembership(store: Store, handler: any) {

        this.loading(true);
        this.memberApiService.checkingMembership(store.brandId).subscribe((membership) => {
            const member = this.memberService.getMember();
            const has = membership && membership.length > 0;
            if (has) {
                member.membership = membership[0];
                // handler();
            }
            handler(has);
            // this.loading(false);
        }, err => {
            this.loading(false);

            if (err.status === 401) {

                this.showAlert(err.status, err['_body'], [{
                    text: this.t.instant('buttons.ok'),
                    handler: () => {
                        this.memberService.clearSession();
                        this.memberService.offers = null;
                        this.memberService.hasMembership = false;
                        this.navigationService.popToRoot();
                        this.loading(false);
                    }
                }]).then(alert => {
                    alert.present();
                });
            } else {
                this.showError(err);
            }
        }, () => {
            this.loading(false);
        });


    }

    getDefaultImage(isCategory: boolean, brand: Data, height: number) {
        if (!brand) {
            return;
        }
        const theme = brand['themes']['byod'];

        if (theme) {

            if (isCategory && theme['category-fb'] && theme['category-fb'].length > 0) {
                const catImage = theme['category-fb'][0] as Image;
                return this.getImageUrl(catImage, height);

            }

            if (!isCategory && theme['item-fb'] && theme['item-fb'].length > 0) {
                const itemImage = theme['item-fb'][0] as Image;
                return this.getImageUrl(itemImage, height);

            }

        }
        return null;

    }

    getSplashBackground(brand: Data, height: number) {
        const theme = brand['themes']['byod'];

        if (theme) {

            if (theme['welcome'] && theme['welcome'].length > 0) {
                const splash = theme['welcome'][0] as Image;
                return this.getImageUrl(splash, height);

            }

        }
        return null;

    }

    checkingMobileTimeAvailable(): boolean {
        const today = new Date();
        if (!this.configService.serverTime) {
            return true;
        }

        return (today.getTime() + (60 * 60000) > this.configService.serverTime && this.configService.serverTime > today.getTime() - (60 * 60000));
    }


    backButtonClick(toRoot?: boolean, route?: ActivatedRoute, isReorder = false) {
        // if the outlet has page stack, pop
        // else redirect to root page
        console.log('on back button click');
        console.log('is navigating?', this.navigationService.navigating);
        if (isReorder) {
            this.pushByName('BrandStoreListPage', { courtId: this.orderService.courtId }, {replaceUrl: true});
            return;
        }
        if (!toRoot) {
            console.log('back by pop');
            this.googleAnalyticEventTrack('back');
            // if (this.backByLocation) {
            //     this.location.back();
            //     window.onpopstate
            // window.history.replaceState({},this.location.path());
            // } else {
            if (!this.navigationService.poping) {
                this.navigationService.lastParams = null;
                console.log('pop start');
                this.navigationService.popPage();
            } else {
                console.log('pop is in progress, try again later');
            }


            // }
        } else {
            this.googleAnalyticEventTrack('back');
            console.log('back by navigate to root');
            this.navigationService.popToRoot();
        }
        // this.stackController.pop(0)
        // this.navController.goBack();
        // this.navController.pop();
    }

    async homeButtonClick() {
        // no need alert msg
        this.orderService.isHomeButton = true;
        if (this.multOrderManager.enableMutlOrder) {
            // TODO: check logic
            this.navController.navigateRoot(ROOT_URL, {});
            this.multOrderManager.clearAll();
            this.orderService.isHomeButton = false;
        } else if (!this.multOrderManager.enableMutlOrder && this.orderManager.getOrder().orderitems.length === 0) {
            // this.router.
            // TODO: check logic
            this.navController.navigateRoot(ROOT_URL, {});
            this.orderManager.clear();
            this.orderService.isHomeButton = false;
        } else {
            // need alert msg
            const alert = await this.alertController.create({
                header: this.t.instant('pages.brand-store-list.alert-title'),
                buttons: [
                    {
                        text: this.t.instant('buttons.cancel'),
                        handler: () => {
                            // to do
                            // this.googleAnalyticEventTrack('back-button-event', 'cancel');

                        }
                    },
                    {
                        text: this.t.instant('buttons.ok'),
                        handler: () => {
                            // to do
                            // TODO: check logic
                            // this.googleAnalyticEventTrack('back-button-event', 'back');
                            this.navController.navigateRoot(ROOT_URL);
                            if (this.multOrderManager.enableMutlOrder) {
                                this.multOrderManager.clearAll();

                            } else {
                                this.orderManager.clear();

                            }
                            this.orderService.isHomeButton = false;


                        }
                    }
                ]
            });
            alert.present();
        }

    }


    public pageName(): string {
        return '';
    }

    private getColor(data: Data, name: string) {

        const colors = data['colors'];
        if (!colors) {
            return null;
        }

        return colors[name];

    }

    /**********  for , 根據key,判斷是否在今天內顯示過 start  ********/
    /// key = storeid  + ad.stage
    saveTodayEndTime(key: string) {
        if (!key) return;
        let endTime = new Date().setHours(23, 59, 59, 0);
        // this.configService.putPreference(key, endTime);
        this.configService.setCookie(key, String(endTime));
    }

    private getImageUrl(image: Image, height: number) {

        /*
        var url = image.url;

        if (!url) return null;
        if (url.indexOf("googleuser") > 0 && height) {
            var suffix = "=s" + height.toFixed(0);
            url += suffix;
        }


        if (url.indexOf("http://") === 0 && url.indexOf("localhost") === -1) {
            url = url.replace("http:", "https:");
        }

        return url;
        */
        const url = image.url;
        if (!url) {
            return null;
        }
        return Data.getImageUrl(image.url, height, false);
    }

    private addCss(sheet: any, className: string, cssAttribute: string, cssValue: string) {

        const rule = className + '{' + cssAttribute + ':' + cssValue + '}';
        console.log('adding css', rule);
        // sheet['insertRule'](rule, this.staticService.themeCount++);
    }

    private addCssInHead(id: string, className: string, cssAttribute: string, cssValue: string) {

        let css = className + '{' + cssAttribute + ':' + cssValue + '}',
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style'),
            element = document.getElementById(id);

        if (element) {
            element.remove();
        }
        style.appendChild(document.createTextNode(css));
        style.setAttribute('id', id);
        head.appendChild(style);
    }

    private settingCssClass(id: string, className: string, cssAttribute: string, cssValue: string) {
        document.getElementById(id).getElementsByClassName(className)[0].setAttribute('style', cssAttribute + ':' + cssValue);

    }

    public isPhoneNumberVaild(value): Boolean {
        return isPhoneNumberVaild(value);
    }

    // public async translate(key: string) {
    //     if (!key) return null;

    //     let value = await new Promise<string>(resolve => {
    //         this.t.get(key).subscribe(v => {
    //             resolve(v);
    //         });
    //     })
    //     return value;
    // }
    // public translateCb(key: string ,callback?) {
    //     if (!key) return;
    //     this.t.get(key).subscribe(v => {
    //         callback(v);
    //     });
    // }
}

