import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MyPreloadStrategy} from './core/my-preload-strategy';

/**
 *  conclusion: use loadChildren for Lazy Load Module, use component for Module Load when init?
 *  if use component, must include the page in declarations in app.module.ts
 *
 *
 * */

const routes: Routes = [
    // Deprecated
    // {
    //     path: 'code/:code',
    //     loadChildren: './pages/code/code-page.module#CodePageModule'
    // },
    // Deprecated End
    // {
    //     path: 'brand/:brandId/status/:status',
    //     loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    // },
    // {
    //     path: 'court-store-list/:courtId/status/:status',
    //     loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    // },
    // {
    //     path: 'store/:storeId/spot/:spot/key/:key/mode/:mode/status/:status',
    //     loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    // },
    {
        path: 'court/:courtId/status/:status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule',
    },
    {
        path: 'court/:courdId/summary-list/:sessionId',
        loadChildren: './routes/summary/summary-list/summary-list.module#SummaryListModule'
    },
    {
        path: 'court/:courtId/healthcheck',
        loadChildren: './pages/byod-brand-splash/byod-brand-splash.module#ByodBrandSplashModule',
        // loadChildren: './pages/brand-store-list/brand-store-list.module#BrandStoreListModule',
    },
    {
        path: 'court/:courtId',
        loadChildren: './pages/byod-brand-splash/byod-brand-splash.module#ByodBrandSplashModule',
        // loadChildren: './pages/brand-store-list/brand-store-list.module#BrandStoreListModule',
    },
    {
        path: 'court/:courtId/order-status',
        loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule',
    },
    // {
    //     path: 'store/:storeId/spot/:spot/key/:key/status/:status',
    //     loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    // },
    // {
    //     path: 'store/:storeId/status/:status',
    //     loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    // },
    // {
    //     path: 'order-status/:status',
    //     loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    // },
    // {
    //     path: 'order-status',
    //     loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    // },
    // {
    //     path: 'store/:storeId/mode/:mode/status/:status',
    //     loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    // },
    // {
    //     path: 'store/:storeId/mode/:mode/status/:status/reDirectOrderId/:payMethod',
    //     loadChildren: './routes/summary/order-status/order-status.module#OrderStatusModule'
    // },
    // {
    //     path: 'brand/:brandId',
    //     loadChildren: './pages/byod-brand-splash/byod-brand-splash.module#ByodBrandSplashModule'
    // },
    // {
    //     path: 'byod-summary',
    //     loadChildren: './routes/summary/byod-summary/byod-summary.module#ByodSummaryModule'
    // },

    // {
    //     path: 'storeId/:storeId/type/:type/session/:sessionId',
    //     loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',

    // }, {
    //     path: 'store/:store/spot/:spot/key/:key/mode/:mode',
    //     loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',

    // }, {
    //     path: 'store/:store/spot/:spot/key/:key',
    //     loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',

    // }, {
    //     path: 'store/:store/spot/:spot',
    //     loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',
    // },
    // {
    //     path: 'store/:store',
    //     loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',
    // },

    // {
    //     path: 'bkHistory/:sessionId',
    //     loadChildren: './routes/summary/byod-summary/byod-summary.module#ByodSummaryModule'
    // },

    // {
    //     path: 'bk',
    //     loadChildren: './pages/delivery-information/delivery-information.module#DeliveryInformationModule'
    // },
    // {
    //     path: 'bk/:sessionId',
    //     loadChildren: './pages/delivery-information/delivery-information.module#DeliveryInformationModule'
    // },
    /**
     *  for MGPS 3D Secure Verification
     * */
    // {
    //     path: 'close/:status',
    //     loadChildren: './pages/close/close.module#CloseModule'
    // },
    // {
    //     path: 'home#/close/:status',
    //     redirectTo: 'close/:status'
    // },

    /**
     * Routes below are verified ok
     * */
    // {
    //     path: 'court-store-list/:courtId',
    //     loadChildren: './pages/brand-store-list/brand-store-list.module#BrandStoreListModule',
    //     // component: BlankPage,
    //     // children: [{
    //     //     path: '',
    //     // }]
    // },
    // {
    //     path: 'store/:store/mode/:mode',
    //     loadChildren: './pages/byod-splash/byod-splash.module#ByodSplashModule',
    //     // children: [{
    //     //     path: '',
    //     // }]
    // },
    {
        path: 'test',
        // canActivate: [HomeGuardGuard],
        loadChildren: './pages/test/test.module#TestPageModule'
    },
    {
        path: '',
        redirectTo: 'court/5726866849660928',
        pathMatch: 'full',
        // loadChildren: './pages/test/test.module#TestPageModule'

    }
    // {path: 'blank', loadChildren: './blank/blank.module#BlankPageModule'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        enableTracing: false,
        relativeLinkResolution: 'corrected',
        onSameUrlNavigation: 'reload',
        urlUpdateStrategy: 'eager',
        preloadingStrategy: MyPreloadStrategy
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
